import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {HeadersUtil} from "utils/headersUtil";
import ObjectUtil from "utils/objectUtil";
import {ApiUrlUtil} from "../utils/apiUrlUtil";
import {ResponseApi, ResponseResultCode} from "../model/response-api";

export interface ComCdModel {
  comCd: string;
  uppoComCd?: string;
  regrId: string;
  regDate: Date;
  chgrId: string;
  chgDate: Date;
  comCdGrpNm: string;
  comCdNm: string;
  comCdExpl: string;
  code:[]
}

interface codeSliceModel {
  cpCodes: Record<string, ComCdModel[]>
  code:Record<string, any[]>
}
const initialState: codeSliceModel = {
  cpCodes: {},
  code:{},
}

export const getCpCodes = createAsyncThunk('codes/getCpCodes', async() => {
  return axios.get(process.env.REACT_APP_URL_CP + "/com/all", {
    headers: HeadersUtil.getHeadersAuth()
  });
});

export const getCommonCodes = createAsyncThunk("code/commonCode", async () => {
  return axios.get(process.env.REACT_APP_URL_CP + "/com/select", {
    headers: HeadersUtil.getHeadersAuth()
  })
})

export const getCommonCode = createAsyncThunk("code/comCode", async() => {
  const url = process.env.REACT_APP_URL_CO + '/com/code';
  const response = await axios.get(ApiUrlUtil.buildQueryString(url), {
    headers: HeadersUtil.getHeadersAuth(),
  });

  if(response && "OK" === response.statusText) {
    return response.data;
  }
});

export const codeSlice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    insertNewCpCode: (state, action) => {
      // state.cpCodes.
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCpCodes.pending, () => {})
    builder.addCase(getCpCodes.fulfilled, (state, action: any) => {
      const cpCodes = action.payload.data.data as ComCdModel[];
      if(cpCodes) {
        state.cpCodes = ObjectUtil.groupBy(cpCodes, i => i.comCdGrpNm);
      }
    })
    builder.addCase(getCpCodes.rejected, (state) => {
      state.cpCodes = {};
    })
    .addCase(getCommonCode.pending, () => {})
    .addCase(getCommonCode.fulfilled, (state, action: any) => {
      const response = action.payload as ResponseApi<Record<string, any[]>>;
      if(response && ResponseResultCode.Y === response.resultCode) {
        state.code = response.data;
      }
    })
    .addCase(getCommonCode.rejected, state => {
      state.code = {};
    })
  }
})

export default codeSlice.reducer