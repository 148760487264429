import { ApiUrlUtil } from "utils/apiUrlUtil";
import axios from "axios";
import { HeadersUtil } from "utils/headersUtil";
import Api from "services/common/Api";

export class UnitService extends Api {
  private static _service: UnitService;
  static readonly deleteRow: any;

  // instance 생성
  public static getInstance(): UnitService {
    if (!UnitService._service) {
      UnitService._service = new UnitService();
    }
    return UnitService._service;
  }

  public getAllUnits() {
    const url = ApiUrlUtil.buildQueryString(process.env["REACT_APP_URL_CP"] + "/unit/units");
    return super.get(url);
  }

  public getUnit(unitId: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/unit/getUnit/" + unitId
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

}
