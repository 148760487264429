import * as process from "process";
import {ScnroModel} from "../../model/rp/ScnroModel";
import Api from "../common/Api";
import {ApiUrlUtil} from "../../utils/apiUrlUtil";

export class DbAdminService extends Api {

  private static service: DbAdminService;

  private static USER_COUNT: string = process.env['REACT_APP_URL_CO'] + "/company/count";
  private static RE100_FUFL: string = process.env['REACT_APP_URL_RP'] + "/rdmp/reStatus";

  public static getInstance(): DbAdminService {
    if (!DbAdminService.service) {
      DbAdminService.service = new DbAdminService();
    }
    return DbAdminService.service;
  }

  public async getUserCount() {
    return await super.get<any>(DbAdminService.USER_COUNT);
  }

  public async getRe100Fufl() {
    return await super.get<any>(DbAdminService.RE100_FUFL);
  }

  public getBzkndList() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/bzknd/list");
    return super.get<any>(url);
  }

  public async getStatusByScale() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_RP + "/smcnCert/statusByScale");
    return super.get<any>(url);
  }

  public async getRe100BzkndList(re100Join: string) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/company/bzknd", {re100Join: re100Join});
    return super.get<any>(url);
  }

  public async getPowerUsage(search: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/mg/power/usage", search);
    return super.get<any>(url);
  }

  public async getGeneration(search: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/mg/power/generation", search);
    return super.get<any>(url);
  }

  public async getComStatus() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/company/ranking");
    return super.get<any>(url);
  }

  public async getStatistics() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/company/statistics");
    return super.get<any>(url);
  }


}