import { ApiUrlUtil } from "utils/apiUrlUtil";
import axios from "axios";
import { HeadersUtil } from "utils/headersUtil";
import Api from "services/common/Api";
import { MngFuelGhgsEmissCoef } from "model/cp/mngFuelGhgsEmissCoef";
import { MngCalfm } from "model/cp/mngCalfm";

export class BasicInfoService extends Api {
  private static service: BasicInfoService;
  static readonly deleteRow: any;

  // instance 생성
  public static getInstance(): BasicInfoService {
    if (!BasicInfoService.service) {
      BasicInfoService.service = new BasicInfoService();
    }
    return BasicInfoService.service;
  }

  // 연료명, 단위 조회 (select box 항목)
  public getFuelByYear(aplyYy: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/mngFuelGhgsEmissCoef/fuelName/select/" + aplyYy
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  // 연료명, 단위 조회 (select box 항목)
  public getFuelRefByYear(aplyYy: any) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/refNatFvrQnt/fuelName/select/" + aplyYy
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  // 에너지명, 단위 조회 (select box 항목)
  public getEnrgByYear(aplyYy: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/refSplyEnrg/enrgName/select/" + aplyYy);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getIncnrByYear(aplyYy: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/refIncnrEmsnCoef/wstName/select/" + aplyYy);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getPrcsByYear(aplyYy: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/refProcessEmission/wstName/select/" + aplyYy);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public async getMaxDgrByBzknd() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/bzknd/max-dgr");
    return await super.get<any>(url);
  }

  public async duplicationData() {
    return await super.post(process.env.REACT_APP_URL_CO + "/bzknd/duplication");
  }
}
