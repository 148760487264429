import ArcGrid, {
  CustomCommonCodeRenderer,
  CustomSelectUnitCodeRenderer,
  GridColumn,
  GridComplexHeader,
  GridEditor,
  GridEditorInfo,
} from "components/arc/ArcGrid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ComCdService } from "services/cp/ComCdService";
import ArcDatePicker from "components/arc/ArcDatePicker";
import { parseNumberWithCommaDecimal } from "utils/stringUtil";
import moment from "moment";
import { CodeUtil } from "utils/codeUtil";
import { UnitService } from "services/cp/UnitService";
import { HttpStatusCode } from "axios";
import { EmissionService } from "services/cp/EmissionService";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { useAppSelector } from "stores/hook";
import { AuthConstant } from "constants/authConstant";
import { UserInfoUtil } from "utils/userInfoUtil";

export default function NationalFeverQnt() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const gridRef: any = useRef<typeof ArcGrid>();

  const { t } = useTranslation();
  const [gridCanDraw, setGridCanDraw] = useState<boolean>(false);

  const [fuelCd, setFuelCd] = useState<any[]>([]);
  const [unitCd, setUnitCd] = useState<any[]>([]);
  const [year, setYear] = useState<number>(moment().year());

  const [cecicc, setCecicc] = useState<any[]>([]);

  useEffect(() => {
    EmissionService.getInstance().getEmissionCecicc()
      .then((response) => {
        if (response.data.data.length > 0) {
          setCecicc(response.data.data);
        } else {
          setCecicc([]);
        }
      })
      .catch(() => toast.error(t('common.message.error.system')));

    ComCdService.getInstance().getComCdExpl("EMKS_FVR_FUEL_CL_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setFuelCd(cdOptions);
        } else {
          setFuelCd([]);
        }
      }).catch(() => toast.error(t('common.message.error.system')));

    UnitService.getInstance().getAllUnits()
      .then((response) => {
        if (response) {
          if (HttpStatusCode.Ok === response.statusCode) {
            const unitOptions = CodeUtil.makeCpUnitOptions(response.data as any[]);
            setUnitCd(unitOptions);
          }
        }
      })
      .catch(() => toast.error(t('common.message.error.system')));
  }, []);


  const gridNumberParse = (e: any) => {
    if (e.value || 0 === e.value) {
      return parseNumberWithCommaDecimal(e.value);
    }
    return "";
  }

  const refMapNumber = (e: any, name: string) => {
    let result = "";
    if (e.row.refMap !== undefined) {
      e.row.refMap.forEach((item: any) => {
        if (item[name]) {
          result = item[name];
        }
      })
      if (e.row.refMap) {
        if (e.value !== null && /^[0-9.]+$/.test(e.value)) {
          return e.value !== "" ? parseNumberWithCommaDecimal(e.value) : parseNumberWithCommaDecimal(result)
        } else {
          return parseNumberWithCommaDecimal(result);
        }
      }
    }
    return e.value ? parseNumberWithCommaDecimal(e.value) : result;
  }

  const numberEditor = (length: number, decimal: number): Partial<GridEditorInfo> => {
    return {
      options: {
        type: "number",
        validation: {
          dataType: "number",
          regExp:
            new RegExp(`^\\d{0,${length - decimal}}(\\.\\d{0,${decimal}})?$`),
          regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
            len: length,
            dec: decimal
          })
        }
      },
    }
  }
  const numberEditorEmission = (length: number, decimal: number): Partial<GridEditorInfo> => {
    return {

      options: {
        type: "number",
        validation: {
          validatorFn: (value: any) => {
            return (
              (gridRef.current && (gridRef.current.getInstance().getData()[0]?.dtlsList !== undefined)) || (value !== '' && value !== null)
            )
          },
          regExp:
            new RegExp(`^\\d{0,${length - decimal}}(\\.\\d{0,${decimal}})?$`),
          regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
            len: length,
            dec: decimal
          })
        }
      },
    }
  }

  // 그리드 컬럼
  const defaultGridColumns = (): GridColumn[] => [
    {
      header: "emsnCoefId",
      name: "emsnCoefId",
      hidden: true,
    },
    {
      header: "연료명",
      name: "fvrFuelClCd",
      align: "center",
      width: 140,
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "연료 단위",
      name: "fuelUnitCd",
      align: "center",
      width: 80,
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.unitNm;
      },
    },
    {
      header: "에너지법 시행규칙 상",
      name: "mjUnit",
      align: "center",
      width: 140,
      whiteSpace: "normal",
      formatter: function (e: any) {
        const row = e.row;
        if (row.fuelUnitCd?.unitNm) {
          return "MJ/" + row.fuelUnitCd?.unitNm;
        } else {
          return "-";

        }
      },
    },
    {
      header: "TJ로 환산 시",
      name: "tjUnit",
      align: "center",
      width: 140,
      whiteSpace: "normal",
      formatter: function (e: any) {
        const row = e.row;
        switch (row.fuelUnitCd?.unitNm) {
          case "Nm³":
            return "1,000,000Nm³";
          case "L":
            return "TJ/1,000m³";
          case "kg":
            return "TJ/Gg";
          default:
            return "-";
        }
      },
    },
    {
      header: "총 발열량",
      name: "totFvrQnt",
      align: "right",
      width: 80,
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: "순 발열량",
      name: "pureFvrQnt",
      align: "right",
      width: 80,
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: "이산화탄소 배출계수 (kgCO₂/TJ)",
      name: "cbdxEmsnCoef",
      align: "right",
      width: 110,
      whiteSpace: "normal",
      formatter: gridNumberParse,
    }
  ];

  //그리드 수정
  const defaultGridEditor = (): GridEditor => (
    {
      defaultRowDataSet: { aplyYy: year },
      editableColumns: [
        {
          columnName: "fvrFuelClCd",
          editorType: CustomCommonCodeRenderer,
          options: {
            comCd: fuelCd,
            validation: {
              validatorFn: (value: any) => {

                if (value === null || value === undefined) return false;
                if (value?.comCdExpl === undefined) return false;
                const gridInstance = gridRef.current?.getInstance();
                if (!gridInstance) return false;
                const data = gridInstance.getData();
                if (!Array.isArray(data) || data.length === 0) return false;
                return data.filter((e: any) => e?.fvrFuelClCd?.comCdExpl === value.comCdExpl).length === 1;
              }
            }
          }
        },
        {
          columnName: "fuelUnitCd",
          editorType: CustomSelectUnitCodeRenderer,
          options: {
            comCd: unitCd,
            validation: {
              validatorFn: (value: any) => {
                return value !== null && value?.unitNm !== undefined;
              }
            }
          }
        },
        {
          columnName: "totFvrQnt",
          editorType: "text",
          ...numberEditor(15, 4),
        },
        {
          columnName: "pureFvrQnt",
          editorType: "text",
          ...numberEditor(15, 4)
        },
        {
          columnName: "cbdxEmsnCoef",
          editorType: "text",
          ...numberEditor(15, 4)
        },
      ],
    });
  const [gridEditor, setGridEditor] = useState<GridEditor>(defaultGridEditor);
  const [gridColumns, setGridColumns] = useState<GridColumn[]>(defaultGridColumns);

  // 그리드 헤더
  const gridHeader: GridComplexHeader = {
    height: 90,
    complexColumns: [
      {
        header: "단위",
        name: "unit",
        childNames: ["mjUnit", "tjUnit"],
      },
      {
        header: "메탄 배출계수(kgCH₄/TJ)",
        name: "kgCH₄/TJ",
        childNames: cecicc.map(f => "methn_" + f.emksCrbnEmsnCoefIndsClCd),
      },
      {
        header: "아산화질소 배출계수(kgN₂O/TJ)",
        name: "kgN₂O/TJ",
        childNames: cecicc.map(f => "dnmx_" + f.emksCrbnEmsnCoefIndsClCd),
      },
    ],
  };

  useEffect(() => {
    if (cecicc.length > 0) {
      const columns = defaultGridColumns();
      const gridEditor = defaultGridEditor();
      cecicc.forEach(f => {
        columns.push({
          header: f.crbnEmsnCoefIndsClCdNm,
          name: `methn_${f.emksCrbnEmsnCoefIndsClCd}`,
          align: "center",
          whiteSpace: "normal",
          formatter: (e: any) => {
            return refMapNumber(e, `methn_${f.emksCrbnEmsnCoefIndsClCd}`)
          },
        })
      });
      cecicc.forEach(f => {
        columns.push({
          header: f.crbnEmsnCoefIndsClCdNm,
          name: `dnmx_${f.emksCrbnEmsnCoefIndsClCd}`,
          align: "center",
          whiteSpace: "normal",
          formatter: (e: any) => {
            return refMapNumber(e, `dnmx_${f.emksCrbnEmsnCoefIndsClCd}`)
          },
        })
      });
      cecicc.forEach((f) => {
        gridEditor.editableColumns.push({
          columnName: `methn_${f.emksCrbnEmsnCoefIndsClCd}`,
          editorType: "text",
          ...numberEditorEmission(20, 9)
        });
      })
      cecicc.forEach(f => {
        gridEditor.editableColumns.push({
          columnName: `dnmx_${f.emksCrbnEmsnCoefIndsClCd}`,
          editorType: "text",
          ...numberEditorEmission(20, 9)
        });
      })
      setGridColumns(columns);
      setGridEditor(gridEditor);
      setGridCanDraw(true);
    }


  }, [cecicc, year, fuelCd]);

  const makeGrid = useCallback(() => {
    if (gridCanDraw) {
      return (
        <ArcGrid
          gridRef={gridRef}
          key={`${fuelCd.length}-${unitCd.length}`} // unitCd 상태가 변경될 때마다 key가 변경되어 그리드가 다시 렌더링
          datasource={{
            readData: { url: "/cp/refNatFvrQnt", method: "GET", initParams: { year: year } },
          }}
          columns={gridColumns}
          usePagination={false}
          useGridInfoHeader={true}
          complexHeader={gridHeader}
        />
      );
    }
  }, [gridCanDraw, gridColumns]);

  const makeGridAdmin = useCallback(() => {
    if (gridCanDraw) {
      return (
        <ArcGrid
          gridRef={gridRef}
          key={`${fuelCd.length}-${unitCd.length}`} // unitCd 상태가 변경될 때마다 key가 변경되어 그리드가 다시 렌더링
          datasource={{
            readData: { url: "/cp/refNatFvrQnt", method: "GET", initParams: { year: year } },
            createData: { url: "/cp/refNatFvrQnt", method: "POST" },
            updateData: { url: "/cp/refNatFvrQnt", method: "PUT" },
            deleteData: {
              url: "/cp/refNatFvrQnt", method: "DELETE", serializer(params) {
                const ids: any = params.deletedRows?.map((row: any) => ({ emsnCoefId: row.emsnCoefId }))
                return encodeURI(`deletedRows=${JSON.stringify(ids)}`)
              }
            },
          }}
          columns={gridColumns}
          rowHeaders={["checkbox"]}
          usePagination={false}
          useGridInfoHeader={true}
          complexHeader={gridHeader}
          editor={gridEditor}
        />
      );
    }
  }, [gridCanDraw, gridColumns]);

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <h3 className="titT1">연료별 국가 고유 발열량</h3>
            <ArcDatePicker onChange={(data) => setYear((data.fromDate ?? new Date()).getFullYear())}
              viewType="year"
              fromDate={String(year)}
              showButtonBar={false}
              id="selectedYear"
              name="selectedYear" />
          </div>
          <div className="fs14">
            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ? <>{makeGridAdmin()}</>
              : <>{makeGrid()}</>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
