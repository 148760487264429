import React, {useEffect, useRef, useState} from 'react'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import {useNavigate} from 'react-router-dom';
import {ScnroService} from "services/rp/ScnroService";
import {ResponseResultCode} from "model/response-api";
import {RdmpCondService} from "services/rp/RdmpCondService";
import {SmcnCertService} from "services/rp/SmcnCertService";
import {MatchingTargetService} from "services/rp/MatchingTargetService";
import {PortfolioService} from "services/rp/PortfolioService";
import Slider from "react-slick";
import CountUp from "react-countup";
import ArcButton, {ButtonIcon, ButtonType} from "components/arc/ArcButton";
import {DbAdminService} from "services/db/DbAdminService";
import moment from "moment/moment";

HighchartsMore(Highcharts);

export default function DbAdminRe100Status() {
  let sliderRef: any = useRef(null);
  const date = moment().format('YYYY.MM.DD');
  const navigate = useNavigate();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    arrows: false,
  };
  const [userCount, setUserCount] = useState<any>({});
  const [re100FuflCount, setRe100FuflCount] = useState<any>({});
  const [rdmpCount, setRdmpCount] = useState(0);
  const [sumScnroCount, setSumScnroCount] = useState(0);
  const [inqCnt, setInqCnt] = useState(0);
  const [comStatus, setComStatus] = useState<any>({});

  const [one, setOne] = useState<any>({});
  const [two, setTwo] = useState<any>({});
  const [three, setThree] = useState<any>({});
  const [four, setFour] = useState<any>({});
  const [five, setFive] = useState<any>({});

  const [graphA, setGraphA] = useState({
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: '120'
    },
    title: {text: ''},
    exporting: {enabled: false},
    credits: {enabled: false},
    xAxis: {
      categories: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
      title: {text: ''},
      gridLineColor: '#666b78',
      tickColor: '#666b78',
      labels: {style: {color: '#fff'}},
    },
    yAxis: {
      title: {text: ''}, gridLineColor: '#666b78', tickColor: '#666b78', lineColor: '#666b78',
      labels: {
        style: {
          color: '#fff'
        },
        formatter: function (this: any) {
          return Highcharts.numberFormat(this.value, 0, '', '');
        }
      },
    },
    tooltip: {
      headerFormat: ''
    },
    plotOptions: {
      series: {
        borderRadius: 0,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        dataLabels: {
          style: {
            textOutline: 0,
          },
          y: -3,
          inside: true,
          enabled: true,
          crop: false,
          overflow: 'none',
          format: '<span class="bar-graph-color-num" style="color:#fff;font-size:.6rem;font-weight:400;">{y}건</span>'
        },
      }
    },
    legend: {
      enabled: false
    },
    series: [{
      name: '매칭현황',
      data: [],
      color: '#4285dc'
    }]
  })
  const [sumSmcnCertAllCount, setSumSmcnCertAllCount] = useState(0);
  const [sumPpaRqs, setSumPpaRqs] = useState({count: 0, sum: 0});
  const [sumPpaRqsIncrease, setsumPpaRqsIncrease] = useState(0);
  const [monthlyPpaRqsCount, setMonthlyPpaRqsCount] = useState({});
  const [fuflMeansGraph, setFuflMeansGraph] = useState({
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: 280,
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0

    },
    title: {
      text: ''
    },
    subtitle: {
      useHTML: true,
      text: `<div style="text-align:center;">
<span class="donut-tit" style="color:#fff;">참여 기업 수</span><br><span class="donut-stit" style="color:#fff;">
<em style="color:#fff;font-size:1.5rem;font-weight:700;">${rdmpCount}</em> 개</span></div>`,
      align: 'center',
      verticalAlign: 'middle',
      x: 5
    },
    legend: {
      enabled: false,
    },
    exporting: {enabled: false},
    credits: {enabled: false},
    tooltip: {
      valueSuffix: '%'
    },
    plotOptions: {
      series: {
        showInLegend: true,
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: 'pie',
        innerSize: '60%',
        //size:'80%',
        allowPointSelect: true,
        dataLabels: {
          useHTML: true,
          enabled: true,
          distance: 0,
          formatter(this: any) {
            return '<div style="text-align:center;"><span class="bar-graph-name" style="display:block;font-size:.5rem;font-weight:400;color:#fff;">' + this.point.name + '</span><span class="bar-graph-color-num" style="position:relative;display:inline-block;margin-top:5px;padding:2px 5px;font-size:.5rem;font-weight:400;border-radius:15px;overflow:hidden;background:' + this.color + '"><span style="position:relative;z-index:1;color:#fff;">' + this.y + '%</span><em style="position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.3);"></em></span></div>';
          }
        },
      }
    },
    colors: ['#545cc2', '#4b96f9', '#44c5dc', '#9effdf', '#85a1ed', '#3a588a', '#5faca6'],
    series: [{
      name: '참여 기업 수',
      type: 'pie',
      data: [
        {
          name: 'PPA제도',
          y: 25
        }, {
          name: 'REC(인증서)구매',
          y: 25
        }, {
          name: '자제건설(자가발전)',
          y: 25
        }, {
          name: '녹색프리미엄요금',
          y: 25
        }
      ]
    }]
  })

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mainscript';
    script.src = "/assets/js/sub.js";
    script.async = true;
    document.body.appendChild(script);

    const scriptConCom = document.createElement('script');
    scriptConCom.id = 'mainscript';
    scriptConCom.src = "/assets/js/con_com.js";
    scriptConCom.async = true;
    document.body.appendChild(scriptConCom);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(scriptConCom);
    };
  }, [rdmpCount, sumScnroCount, sumSmcnCertAllCount, sumPpaRqs, inqCnt, sumPpaRqsIncrease]);

  useEffect(() => {
    getRdmpCount();
    getSumScnroCount();
    getSmcnCertSumAll();
    getPpaRqsCount();
    getInqCntCount();
    getPpaRqsCountUp();
    getPpaRqsMonthlyCount();
    getSumFuflMeans();
    getUserCount();
    getRe100Fufl();
    getComStatus();
    getStatistics();
  }, [rdmpCount]);


  const redirectToPage = (url: string) => {
    navigate(url)
  }

  async function getSumScnroCount() {
    const response = await ScnroService.getInstance().getSumScnroCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setSumScnroCount(response.data);
      } else {
        setSumScnroCount(0);
      }
    }
  }

  async function getRdmpCount() {
    const response = await RdmpCondService.getInstance().getRdmpCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setRdmpCount(response.data);
      } else {
        setRdmpCount(0);
      }
    }
  }

  async function getSmcnCertSumAll() {
    const response = await SmcnCertService.getInstance().getSmcnCertSumAll();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setSumSmcnCertAllCount(response.data);

      } else {
        setSumSmcnCertAllCount(0);
      }
    }
  }

  async function getPpaRqsCount() {
    const response = await MatchingTargetService.getInstance().getPpaRqsCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setSumPpaRqs({...sumPpaRqs, count: response.data.count, sum: response.data.cntrPwrCpct});
      } else {
      }
    }
  }

  // 조회수
  async function getInqCntCount() {
    const response = await PortfolioService.getInstance().getInqCntCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setInqCnt(response.data);
      } else {
        setInqCnt(0);
      }
    }
  }

  // ppa 증가 수
  async function getPpaRqsCountUp() {
    const response = await MatchingTargetService.getInstance().getPpaRqsCountUp();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setsumPpaRqsIncrease(response.data);
      } else {
        setsumPpaRqsIncrease(0);
      }
    }
  }

  // ppa 월별 건수
  async function getPpaRqsMonthlyCount() {
    const response = await MatchingTargetService.getInstance().getPpaRqsMonthlyCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setMonthlyPpaRqsCount(response.data);
        const months: any = new Array(12).fill(0);
        for (const [key, value] of Object.entries(response.data)) {
          const monthIndex = parseInt(key) - 1; // Convert month to 0-based index
          months[monthIndex] = value;
        }
        setGraphA({
          ...graphA, series: [{
            name: '매칭현황',
            data: months,
            color: '#4285dc'
          }]
        })

      } else {
        setMonthlyPpaRqsCount({});
      }
    }
  }

  // 산단 이행수단 현황
  async function getSumFuflMeans() {

    const response = await ScnroService.getInstance().getSumFulflMeans();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {

        setFuflMeansGraph({
          ...fuflMeansGraph,
          subtitle: {
            useHTML: true,
            text: `<div style="text-align:center;">
<span class="donut-tit" style="color:#fff;">참여 기업 수</span><br><span class="donut-stit" style="color:#fff;">
<em style="color:#fff;font-size:1.5rem;font-weight:700;">${rdmpCount}</em> 개</span></div>`,
            align: 'center',
            verticalAlign: 'middle',
            x: 5
          },
          series: [
            {
              name: '참여 기업 수',
              type: 'pie',
              data: [
                {
                  name: 'PPA제도',
                  y: response.data.ppaPercent
                }, {
                  name: 'REC(인증서)구매',
                  y: response.data.recPercent
                }, {
                  name: '자제건설(자가발전)',
                  y: response.data.selfPgPercent
                }, {
                  name: '녹색프리미엄요금',
                  y: response.data.greenpremiumPercent
                }
              ]
            }],
          plotOptions: {
            series: {
              showInLegend: true,
              borderWidth: 0,
              borderRadius: 0,
              colorByPoint: true,
              type: 'pie',
              innerSize: '60%',
              //size:'80%',
              allowPointSelect: true,
              dataLabels: {
                useHTML: true,
                enabled: true,
                distance: 0,
                formatter(this: any) {
                  return '<div style="text-align:center;"><span class="bar-graph-name" style="display:block;font-size:.5rem;font-weight:400;color:#fff;">' + this.point.name + '</span><span class="bar-graph-color-num" style="position:relative;display:inline-block;margin-top:5px;padding:2px 5px;font-size:.5rem;font-weight:400;border-radius:15px;overflow:hidden;background:' + this.color + '"><span style="position:relative;z-index:1;color:#fff;">' + this.y + '%</span><em style="position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.3);"></em></span></div>';
                }
              },
            }
          },
          colors: ['#545cc2', '#4b96f9', '#44c5dc', '#9effdf', '#85a1ed', '#3a588a', '#5faca6'],
        })
      } else {

      }
    }
  }

  async function getUserCount() {

    const response = await DbAdminService.getInstance().getUserCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setUserCount(response.data);
      } else {
        setUserCount({});
      }
    }
  }

  async function getRe100Fufl() {

    const response = await DbAdminService.getInstance().getRe100Fufl();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setRe100FuflCount(response.data);
      } else {
        setRe100FuflCount({});
      }
    }
  }

  async function getComStatus() {
    const response = await DbAdminService.getInstance().getComStatus();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setComStatus(response.data);
      } else {
        setComStatus({});
      }
    }
  }

  async function getStatistics() {
    const response = await DbAdminService.getInstance().getStatistics();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        (response.data).map((value: any, index: number) => {
          if (value.emksIndsCmplxClCd === "1단지") {
            setOne(value);
          } else if (value.emksIndsCmplxClCd === "2단지") {
            setTwo(value);
          } else if (value.emksIndsCmplxClCd === "3단지") {
            setThree(value);
          } else if (value.emksIndsCmplxClCd === "4단지") {
            setFour(value);
          } else if (value.emksIndsCmplxClCd === "5단지") {
            setFive(value);
          }
        })
      } else {
        setOne({});
        setTwo({});
        setThree({});
        setFour({});
        setFive({});
      }
    }
  }

  const addComma = (value: any) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
      <>
        <div className='container'>
          <section className="section active">
            <div className="col-wrap col-minH-wrap col-minH-fix">
              <div className="col-md-2_5 col-h-12">
                <div className="col-wrap col-h-wrap">
                  <div className="col-md-12 pd0">
                    <div className="col-h-8 pdr0 pdl0 col-change">
                      <div className="cont">
                        <div className="h100">
                          <ul className="count-info-lst1">
                            <li className="mgb20">
                              <h5 className="titT1 mgb0">재생에너지 공급</h5>
                              <em><strong data-count="666" className="change">0</strong>MWh<a
                                  onClick={() => redirectToPage("/db/resources")}
                                  style={{cursor: "pointer"}}
                                  className="more-btn"><i aria-hidden="true"
                                                          className="xi-plus"></i><span
                                  className="hid">더보기</span></a></em>
                            </li>
                          </ul>
                          <div className="icon-box-wrap8 mgt10">
                            <div className="titFlex">
                              <span>산단</span>
                              <em><strong data-count="142" className="change">0</strong>MWh</em>
                            </div>
                            <ul>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon9.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">풍력<strong><em data-count="70"
                                                                      className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon10.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">태양광<strong><em data-count="72"
                                                                       className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon11.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">소수력<strong><em data-count="0"
                                                                       className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon12.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">바이오<strong><em data-count="0"
                                                                       className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="icon-box-wrap8 mgt10">
                            <div className="titFlex">
                              <span>배후 단지</span>
                              <em><strong data-count="524" className="change">0</strong>MWh</em>
                            </div>
                            <ul>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon9.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">풍력<strong><em data-count="144"
                                                                      className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon10.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">태양광<strong><em data-count="350"
                                                                       className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon11.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">소수력<strong><em data-count="20"
                                                                       className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                              <li>
                                <div>
                                <span className="img">
                                  <img
                                      src={require("../../assets/images/sub/icon_box_wrap_icon12.png")}
                                      alt=""/>
                                </span>
                                  <span className="txt">바이오<strong><em data-count="10"
                                                                       className="change">0</em>MWh</strong></span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-h-4 pdr0 pdl0">
                      <div className="cont">
                        <div className="titFlex">
                          <h5 className="titT1">전력구매계약(PPA) 현황</h5>
                          <a onClick={() => redirectToPage("/db/transaction")}
                             style={{cursor: "pointer"}}
                             className="more-btn"><i aria-hidden="true"
                                                     className="xi-plus"></i><span
                              className="hid">더보기</span></a>
                        </div>
                        <div id="graph-typeA" className="chart">
                          <HighchartsReact
                              highcharts={Highcharts}
                              options={graphA}
                          />
                        </div>

                        <div className="box-style4 mgt0">
                          <ul className="count-info-lst1">
                            <li>
                              <span>누적 거래</span>
                              {/* <em><strong data-count={sumPpaRqs.count}
                              className="change">{sumPpaRqs.count}</strong>건</em> */}
                              <em>
                                <CountUp end={Number(sumPpaRqs.count) ?? 0} duration={0.5}>
                                  {({countUpRef}) => <strong ref={countUpRef}/>}
                                </CountUp>
                                건
                              </em>
                            </li>
                            <li><span>신규 거래 </span>
                              {/* <em><strong data-count={sumPpaRqsIncrease}
                            className="change">{sumPpaRqsIncrease}</strong>건</em> */}
                            <em>
                              <CountUp end={Number(sumPpaRqsIncrease) ?? 0} duration={0.5}>
                                {({countUpRef}) => <strong ref={countUpRef}/>}
                              </CountUp>
                              건
                            </em>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6_5 col-h-12 pd0">
              <div className="col-wrap col-h-wrap">
                <div className="col-md-12">
                  <div className="col-h-2 pdr0 pdl0">
                    <div className="cont contFlex">
                      <div className="graph-list-slider-wrap">
                        <div className="graph-list-slider" id="graphListSlider">
                          <Slider ref={slider => {
                            sliderRef = slider;
                          }}
                                  {...settings}>
                            <div className="slider-wrap">
                              <ul className="graph-list-type1">
                                <li className="graph-list-gauge">
                                  <div className="txt pdr0">
                                    <h5 className="titT3">
                                      {moment().format('YYYY')} RE100 달성률
                                      <em className="update disB mgt5">최근 업데이트 {moment().format('YYYYMMDD')}</em>
                                    </h5>
                                    <strong data-count={
                                      Math.floor((Number(one.re100Label ?? 0) + Number(two.re100Label ?? 0) + Number(three.re100Label ?? 0)
                                              + Number(four.re100Label ?? 0) + Number(five.re100Label ?? 0))
                                          / (Number(one.joinEntQnt ?? 0) + Number(two.joinEntQnt ?? 0) + Number(three.joinEntQnt ?? 0)
                                              + Number(four.joinEntQnt ?? 0) + Number(five.joinEntQnt ?? 0)))}
                                            className="change">0</strong><em>%</em>
                                  </div>
                                  <div className="graph-count-vertical count-vertical">
                                    <em data-count={
                                      Math.floor((Number(one.re100Label ?? 0) + Number(two.re100Label ?? 0) + Number(three.re100Label ?? 0)
                                              + Number(four.re100Label ?? 0) + Number(five.re100Label ?? 0))
                                          / (Number(one.joinEntQnt ?? 0) + Number(two.joinEntQnt ?? 0) + Number(three.joinEntQnt ?? 0)
                                              + Number(four.joinEntQnt ?? 0) + Number(five.joinEntQnt ?? 0)))}></em>
                                  </div>
                                </li>
                                <li>
                                  <h5 className="titT3">전체 전력사용량</h5>
                                  <strong
                                      data-count={Math.floor(Number(one.totalQnt ?? 0) + Number(two.totalQnt ?? 0) + Number(three.totalQnt ?? 0)
                                          + Number(four.totalQnt ?? 0) + Number(five.totalQnt ?? 0))}>{addComma(Math.floor(Number(one.totalQnt ?? 0) + Number(two.totalQnt ?? 0) + Number(three.totalQnt ?? 0)
                                      + Number(four.totalQnt ?? 0) + Number(five.totalQnt ?? 0)))}</strong><em>MWh</em>
                                </li>
                                <li>
                                  <h5 className="titT3">한국전력 사용량</h5>
                                  <strong
                                      data-count={Math.floor(((Number(one.totalQnt ?? 0)) + Number(two.totalQnt ?? 0) + Number(three.totalQnt ?? 0)
                                              + Number(four.totalQnt ?? 0) + Number(five.totalQnt ?? 0))
                                          - ((Number(one.genQnt ?? 0)) + Number(two.genQnt ?? 0) + Number(three.genQnt ?? 0)
                                              + Number(four.genQnt ?? 0) + Number(five.genQnt ?? 0)))}>
                                    {addComma(Math.floor(((Number(one.totalQnt ?? 0)) + Number(two.totalQnt ?? 0) + Number(three.totalQnt ?? 0)
                                            + Number(four.totalQnt ?? 0) + Number(five.totalQnt ?? 0))
                                        - ((Number(one.genQnt ?? 0)) + Number(two.genQnt ?? 0) + Number(three.genQnt ?? 0)
                                            + Number(four.genQnt ?? 0) + Number(five.genQnt ?? 0))))}
                                  </strong><em>MWh</em>
                                </li>
                                <li>
                                  <h5 className="titT3">신재생 사용량</h5>
                                  <strong
                                      data-count={Math.floor(Number(one.genQnt ?? 0) + Number(two.genQnt ?? 0) + Number(three.genQnt ?? 0)
                                          + Number(four.genQnt ?? 0) + Number(five.genQnt ?? 0))}>{addComma(Math.floor(Number(one.genQnt ?? 0) + Number(two.genQnt ?? 0) + Number(three.genQnt ?? 0)
                                      + Number(four.genQnt ?? 0) + Number(five.genQnt ?? 0)))}</strong><em>MWh</em>
                                </li>
                                <li>
                                  <h5 className="titT3">전체 온실가스 배출량 </h5>
                                  <strong
                                      data-count={Math.floor((((Number(one.totalQnt ?? 0)) + Number(two.totalQnt ?? 0) + Number(three.totalQnt ?? 0)
                                              + Number(four.totalQnt ?? 0) + Number(five.totalQnt ?? 0))
                                          - ((Number(one.genQnt ?? 0)) + Number(two.genQnt ?? 0) + Number(three.genQnt ?? 0)
                                              + Number(four.genQnt ?? 0) + Number(five.genQnt ?? 0))) * 0.4747)}>
                                    {addComma(Math.floor((((Number(one.totalQnt ?? 0)) + Number(two.totalQnt ?? 0) + Number(three.totalQnt ?? 0)
                                            + Number(four.totalQnt ?? 0) + Number(five.totalQnt ?? 0))
                                        - ((Number(one.genQnt ?? 0)) + Number(two.genQnt ?? 0) + Number(three.genQnt ?? 0)
                                            + Number(four.genQnt ?? 0) + Number(five.genQnt ?? 0))) * 0.4747))}
                                  </strong><em>tCO₂</em>
                                </li>
                              </ul>
                            </div>
                            <div className="slider-wrap">
                              <ul className="graph-list-type1 mgt15">
                                <li className="graph-list-gauge">
                                  <div className="txt pdr0">
                                    <h5 className="titT3 mgb0 pdl5">RE100 플래너</h5>
                                    <a onClick={() => redirectToPage("/db/re100Planner")}
                                       style={{cursor: "pointer"}}
                                       className="more-btn">
                                      <i aria-hidden="true" className="xi-plus"></i><span
                                        className="hid">더보기</span></a>
                                    <em className="update disB mgl0 mgt5">최근
                                      업데이트 {date}</em>
                                  </div>
                                </li>
                                <li>
                                  <h5 className="titT3">re100 시뮬레이션<br/> 이용 건수</h5>
                                  <CountUp end={Number(inqCnt) ?? 0} duration={0.5}>
                                    {({countUpRef}) => <strong ref={countUpRef}/>}
                                  </CountUp>
                                  <em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">re100 로드맵<br/> 등록 건수</h5>
                                  <CountUp end={Number(sumScnroCount) ?? 0} duration={0.5}>
                                    {({countUpRef}) => <strong ref={countUpRef}/>}
                                  </CountUp>
                                  <em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">PPA 매칭 건수</h5>
                                  <CountUp end={Number(sumPpaRqs.count) ?? 0} duration={0.5}>
                                    {({countUpRef}) => <strong ref={countUpRef}/>}
                                  </CountUp>
                                  <em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">PPA 계약 용량</h5>
                                  <CountUp end={Number(sumPpaRqs.sum) ?? 0} duration={0.5}>
                                    {({countUpRef}) => <strong ref={countUpRef}/>}
                                  </CountUp>
                                  <em>kW</em>
                                </li>
                              </ul>
                            </div>

                            <div className="slider-wrap">
                              <ul className="graph-list-type1 mgt15">
                                <li className="graph-list-gauge">
                                  <div className="txt pdr0">
                                    <h5 className="titT3 mgb0 pdl5">탄소 플래너</h5>
                                    <a onClick={() => redirectToPage("/db/carbonPlanner")}
                                       className="more-btn"><i
                                        aria-hidden="true" className="xi-plus"></i><span
                                        className="hid">더보기</span></a>
                                    <em className="update disB mgl0 mgt5">최근
                                      업데이트 {date}</em>
                                  </div>
                                </li>
                                <li>
                                  <h5 className="titT3">온실가스 인벤토리<br/> 등록 건수</h5>
                                  <strong data-count="15">0</strong><em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">온실가스 분석 서비스<br/> 이용 건수</h5>
                                  <strong data-count="35">0</strong><em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">온실가스 감축사업<br/> 신청 건수</h5>
                                  <strong data-count="5" className="change">0</strong><em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">에너지 분석 보고서<br/> 발급 건수</h5>
                                  <strong data-count="20">0</strong><em>건</em>
                                </li>
                              </ul>
                            </div>
                            <div className="slider-wrap">
                              <ul className="graph-list-type1 mgt15">
                                <li className="graph-list-gauge">
                                  <div className="txt pdr0">
                                    <h5 className="titT3 mgb0 pdl5">에너지마켓</h5>
                                    <a onClick={() => redirectToPage("/db/energyMarket")}
                                       className="more-btn">
                                      <i aria-hidden="true" className="xi-plus"></i>
                                      <span className="hid">더보기</span>
                                    </a>
                                    <em className="update disB mgl0 mgt5">최근
                                      업데이트 {date}</em>
                                  </div>
                                </li>
                                <li>
                                  <h5 className="titT3">급전 거래 건수</h5>
                                  <strong data-count="25">0</strong><em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">급전 거래 용량</h5>
                                  <strong data-count="450">0</strong><em>MWh</em>
                                </li>
                                <li>
                                  <h5 className="titT3">장외거래 건수</h5>
                                  <strong data-count="10" className="change">0</strong><em>건</em>
                                </li>
                                <li>
                                  <h5 className="titT3">장외거래 실적</h5>
                                  <strong data-count="15450">0</strong><em>천원</em>
                                </li>
                              </ul>
                            </div>
                          </Slider>

                        </div>
                        <div className="control">
                          <ArcButton type={ButtonType.custom}
                                     icon={ButtonIcon.prevAlt}
                                     hasIcon={true}
                                     onClick={() => sliderRef?.slickPrev()}
                                     className="slickPrev"/>
                          <ArcButton type={ButtonType.custom}
                                     icon={ButtonIcon.nextAlt}
                                     hasIcon={true}
                                     onClick={() => sliderRef?.slickNext()}
                                     className="slickNext"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-h-10 pdr0 pdl0">
                    <div className="cont pd0 cont-x-scrl">
                      <div className="map-admin">
                        <strong className="total-figure">산단 RE100현황</strong>
                        <a className="map-complex1" style={{cursor: "pointer"}}>
                          <span className="hid">1단지</span>
                          <div className="map-complex-box1">
                            <strong>
                              <strong>1단지</strong>
                              <em>RE {one?.re100Label ? Math.floor(one?.re100Label) : 0}</em>
                            </strong>
                            <div>
                              <span className="arr"></span>
                              <ul className="count-info-lst1">
                                <li><span>전력사용량</span><em><strong
                                    data-count={one?.totalQnt ? Math.floor(one?.totalQnt) : 0}
                                    className="change">{one?.totalQnt ? addComma(Math.floor(one?.totalQnt)) : 0}</strong>MWh</em>
                                </li>
                                <li><span>재생e공급</span><em><strong
                                    data-count={one?.genQnt ? Math.floor(one?.genQnt) : 0}
                                    className="change">{one?.genQnt ? addComma(Math.floor(one?.genQnt)) : 0}</strong>MWh</em>
                                </li>
                                <li><span>참여기업</span><em><strong data-count={one?.joinEntQnt ?? 0}
                                                                 className="change">{addComma(one?.joinEntQnt) ?? 0}</strong>개</em>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </a>
                        <a className="map-complex2 active" style={{cursor: "pointer"}}>
                          <span className="hid">2,3단지</span>
                          <div className="map-complex-box2">
                            <strong>
                              <strong>2단지</strong>
                              <em>RE {two?.re100Label ? Math.floor(two?.re100Label) : 0}</em>
                            </strong>
                            <div>
                              <span className="arr"></span>
                              <ul className="count-info-lst1">
                                <li><span>전력사용량</span><em><strong
                                    data-count={two?.totalQnt ? Math.floor(two?.totalQnt) : 0}
                                    className="change">{two?.totalQnt ? addComma(Math.floor(two?.totalQnt)) : 0}</strong>MWh</em>
                                </li>
                                <li><span>재생e공급</span><em><strong
                                    data-count={two?.genQnt ? Math.floor(two?.genQnt) : 0}
                                    className="change">{two?.genQnt ? addComma(Math.floor(two?.genQnt)) : 0}</strong>MWh</em>
                                </li>
                                <li><span>참여기업</span><em><strong data-count={two?.joinEntQnt ?? 0}
                                                                 className="change">{addComma(two?.joinEntQnt) ?? 0}</strong>개</em>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="map-complex-box3">
                            <strong>
                              <strong>3단지</strong>
                              <em>RE {three?.re100Label ? Math.floor(three?.re100Label) : 0}</em>
                            </strong>
                            <div>
                              <span className="arr"></span>
                              <ul className="count-info-lst1">
                                <li><span>전력사용량</span><em><strong
                                    data-count={three?.totalQnt ? Math.floor(three?.totalQnt) : 0}
                                    className="change">{three?.totalQnt ? addComma(Math.floor(three?.totalQnt)) : 0}</strong>MWh</em>
                                </li>
                                <li><span>재생e공급</span><em><strong
                                    data-count={three?.genQnt ? Math.floor(three?.genQnt) : 0}
                                    className="change">{three?.genQnt ? addComma(Math.floor(three?.genQnt)) : 0}</strong>MWh</em>
                                </li>
                                <li><span>참여기업</span><em><strong data-count={three?.joinEntQnt ?? 0}
                                                                 className="change">{addComma(three?.joinEntQnt) ?? 0}</strong>개</em>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </a>
                          <a className="map-complex3" style={{cursor: "pointer"}}>
                            <span className="hid">4단지</span>
                            <div className="map-complex-box4">
                              <strong>
                                <strong>4단지</strong>
                                <em>RE {four?.re100Label ? Math.floor(four?.re100Label) : 0}</em>
                              </strong>
                              <div>
                                <span className="arr"></span>
                                <ul className="count-info-lst1">
                                  <li><span>전력사용량</span><em><strong
                                      data-count={four?.totalQnt ? Math.floor(four?.totalQnt) : 0}
                                      className="change">{four?.totalQnt ? addComma(Math.floor(four?.totalQnt)) : 0}</strong>MWh</em>
                                  </li>
                                  <li><span>재생e공급</span><em><strong
                                      data-count={four?.genQnt ? Math.floor(four?.genQnt) : 0}
                                      className="change">{four?.genQnt ? addComma(Math.floor(four?.genQnt)) : 0}</strong>MWh</em>
                                  </li>
                                  <li><span>참여기업</span><em><strong data-count={four?.joinEntQnt ?? 0}
                                                                   className="change">{addComma(four?.joinEntQnt) ?? 0}</strong>개</em>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </a>
                          <a className="map-complex4" style={{cursor: "pointer"}}>
                            <span className="hid">5단지</span>
                            <div className="map-complex-box5">
                              <strong>
                                <strong>5단지</strong>
                                <em>RE {five?.re100Label ? Math.floor(five?.re100Label) : 0}</em>
                              </strong>
                              <div>
                                <span className="arr"></span>
                                <ul className="count-info-lst1">
                                  <li><span>전력사용량</span><em><strong
                                      data-count={five?.totalQnt ? Math.floor(five?.totalQnt) : 0}
                                      className="change">{five?.totalQnt ? addComma(Math.floor(five?.totalQnt)) : 0}</strong>MWh</em>
                                  </li>
                                  <li><span>재생e공급</span><em><strong
                                      data-count={five?.genQnt ? Math.floor(five?.genQnt) : 0}
                                      className="change">{five?.genQnt ? addComma(Math.floor(five?.genQnt)) : 0}</strong>MWh</em>
                                  </li>
                                  <li><span>참여기업</span><em><strong data-count={five?.joinEntQnt ?? 0}
                                                                   className="change">{addComma(five?.joinEntQnt) ?? 0}</strong>개</em>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
              <div className="col-md-2_5 col-h-12 pd0">
                <div className="col-wrap col-h-wrap">
                  <div className="col-md-12">
                    <div className="col-h-3 pdr0 pdl0">
                      <div className="cont">
                        <div className="titFlex mgb10">
                          <h5 className="titT1 mgb0">산단 RE100 참여 현황</h5>
                          <a onClick={() => redirectToPage("/db/re100Participation")}
                             style={{cursor: "pointer"}}
                             className="more-btn"><i aria-hidden="true"
                                                     className="xi-plus"></i><span
                              className="hid">더보기</span></a>
                        </div>
                        <div className="count-info-lst2">
                          <ul>
                            <li className="bg1">
                              <div>
                                <span>총 산단기업</span><em><strong data-count={userCount.totalCount ?? 0}
                                                               className="change">{userCount.totalCount ?? 0}</strong>개</em>
                              </div>
                            </li>
                            <li className="bg2">
                              <div>
                                <span>RE100 이행</span><em><strong data-count={rdmpCount}
                                                                 className="change">{rdmpCount}</strong>개</em>
                                {/*<span>RE100 이행</span><em><strong data-count={70}*/}
                                {/*                                 className="change">70</strong>개</em>*/}
                              </div>
                            </li>
                            <li className="bg3">
                              <div>
                                <span>RE100 관심</span><em><strong data-count={userCount.re100InterestCount ?? 0}
                                                                 className="change">{userCount.re100InterestCount ?? 0}</strong>개</em>
                              </div>
                            </li>
                            <li className="bg4">
                              <div>
                                <span>RE100 달성</span><em><strong data-count={re100FuflCount?.count ?? 0}
                                                                 className="change">{re100FuflCount?.count ?? 0}</strong>개</em>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-h-5 pdr0 pdl0">
                      <div className="cont">
                        <h5 className="titT1">산단 이행수단 현황</h5>
                        <div id="graph-typeB" className="chart" style={{height: "100%"}}>
                          <HighchartsReact
                              highcharts={Highcharts}
                              options={fuflMeansGraph}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-h-4 pdr0 pdl0">
                      <div className="cont">
                        <h5 className="titT1">기업 RE100 현황</h5>
                        <div className="tbl-st scroll-gr-y" style={{height: "calc(100% - 2rem)"}}>
                          <table>
                            <caption>기업 RE100 현황 : 순위, 단지명, 기업명, RE%</caption>
                            <colgroup>
                              <col style={{width: '25%'}}></col>
                              <col style={{width: '20%'}}></col>
                              <col style={{width: 'auto'}}></col>
                              <col style={{width: '15%'}}></col>
                            </colgroup>
                            <thead>
                            <tr>
                              <th scope="col">순위</th>
                              <th scope="col">단지명</th>
                              <th scope="col">기업명</th>
                              <th scope="col">RE%</th>
                            </tr>
                            </thead>
                            <tbody>
                            {comStatus && comStatus.length > 0 && comStatus.map((value: any, index: number) => (
                                <tr key={index}>
                                  <td>{index == 0 ? <img
                                          src={require("../../assets/images/sub/badge_ranking1_ver2.png")}
                                          alt=""
                                          className="disIb vm mgr5"/>
                                      : index == 1 ? <img
                                              src={require("../../assets/images/sub/badge_ranking2_ver2.png")}
                                              alt=""
                                              className="disIb vm mgr5"/>
                                          : index == 2 ? <img
                                                  src={require("../../assets/images/sub/badge_ranking3_ver2.png")}
                                                  alt=""
                                                  className="disIb vm mgr5"/>
                                              : ""}{index + 1}위
                                  </td>
                                  <td>{value?.emksIndsCmplxClCd}</td>
                                  <td className="al">{value?.bizrNm}</td>
                                  <td>RE{Math.floor(value?.re100Qnt)}</td>
                                </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
  )
}
