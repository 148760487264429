import { ApiUrlUtil } from "utils/apiUrlUtil";
import axios from "axios";
import { HeadersUtil } from "utils/headersUtil";
import Api from "services/common/Api";

export class ComCdService extends Api {
  private static _boardService: ComCdService;
  private static DOWNLOAD_URL: string = process.env["REACT_APP_URL_CP"] + "/downloadFile";
  static readonly deleteRow: any;

  // instance 생성
  public static getInstance(): ComCdService {
    if (!ComCdService._boardService) {
      ComCdService._boardService = new ComCdService();
    }
    return ComCdService._boardService;
  }

  public getComCdExpl(comCdGrpNm: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/com/select/" + comCdGrpNm);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }
}
