import React, { Fragment } from "react";
import { useAppSelector } from "stores/hook";

export default function PageTitle() {
  const currentPage = useAppSelector(state => state.userMenu.currentPage);

  return (
    <Fragment>
      <h2 className="ht-tit">{currentPage}</h2>
    </Fragment>
  );
}