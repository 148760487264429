import ArcButton from "components/arc/ArcButton";
import ArcFileUploader, { dbFileInfo } from "components/arc/ArcFileUploader";
import ArcInput from "components/arc/ArcInput";
import ArcSelectbox, { SelectboxSize } from "components/arc/ArcSelectbox";
import ArcTextArea from "components/arc/ArcTextArea";
import AppAlert from "components/common/AppAlert";
import { CrudConstant } from "constants/crudConstant";
import { JsxElementConstant } from "constants/jsxElementConstant";
import { FileInfoModel } from "model/common/fileInfoModel";
import { DocuMoni } from "model/cp/DocuMoni";
import { ResponseResultCode } from "model/response-api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openModal } from "reducers/modalSlice";
import { showAndHideSpinner } from "reducers/spinnerSlice";
import { MhdlgService } from "services/cp/MhdlgService";
import { useAppDispatch } from "stores/hook";

type FileData = {
  name: any;
  size: any;
  fileId: any;
  crud: any;
};

export default function MonitoringInsert() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const locationState = useLocation().state;
  const [form, setForm] = useState<DocuMoni>(locationState ?? new DocuMoni());
  const [dataFiles, setDataFiles] = useState<FileData[]>([]);

  const [state,] = useState(locationState);
  const [newFiles, setNewFiles] = useState<File[]>([]);

  const [list, setList] = useState<any[]>([])

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    MhdlgService.getInstance()
      .getBizListAll()
      .then((response: any) => {
        if (response.data) {
          const cdOptions = makeCommonCodeOptions(response.data);
          console.log(response.data)
          console.log(cdOptions)
          setList(cdOptions);
        } else {
          toast.error(t("common.message.error.system"));
        }
      })
      .catch(() => toast.error(t("common.message.error.system")));
  }

  function makeCommonCodeOptions(data: any[]) {
    return data.map((item: any) => ({
      label: item.rlvnDataNm,
      value: item.docId,
    }));
  }

  function handleExistFile(files: any[]) {
    const deletedFiles: any[] = dataFiles.filter((f: any) => !files.includes(f));
    let formFiles = form.file?.files?.map((m: FileInfoModel): FileInfoModel => {
      if (deletedFiles.findIndex((f: any) => f.fileId === m.fileId) >= 0) {
        return { ...m, crud: CrudConstant.D };
      }
      return { ...m };
    });

    setNewFiles((prevFiles) =>
      prevFiles.filter((file) => !deletedFiles.some((f) => file.name === f.name && file.size === f.size))
    );

    setForm({
      ...form,
      file: {
        ...form.file,
        files: formFiles,
      },
    });
  }

  useEffect(() => {
  }, [form])

  useEffect(() => {
  }, [newFiles])

  function handleClickFileAdd(files: File[]) {
    setNewFiles((prevFiles) => [...prevFiles, ...files]);

    const existingFiles = form.file?.files || [];
    setForm({
      ...form,
      file: {
        ...form.file,
        files: [...existingFiles, ...files],
      },
    });
  }

  useEffect(() => {
    let updatedForm = new DocuMoni();
    if (state) {
      updatedForm = JSON.parse(state);
      let dataFile: dbFileInfo[] = [];

      setForm({
        ...form,
        regrId: updatedForm.regrId,
      });
      setForm({
        ...form,
        regDate: updatedForm.regDate,
      });
      if (updatedForm.fileRespons) {
        updatedForm.fileRespons.forEach((value: any) => {
          let fileInfo: any = {};
          fileInfo = value.fileInfoList;
          dataFile.push({
            name: fileInfo.rlFileNm,
            size: fileInfo.fileCpct,
            fileId: fileInfo.atflId,
          });
        });
      }
    }
    setForm(() => updatedForm);
  }, [state]);


  useEffect(() => {
    if (form?.file?.files) {
      const files = form.file.files.map((file: any) => ({
        name: file.realNm ?? file.name,
        size: file.size,
        fileId: file.fileId,
        crud: file.crud
      }));
      setDataFiles(files);
    }
  }, [form?.file?.files]);


  const handleChangeTextArea = (value: any) => {
    setForm({
      ...form,
      explCtt: value,
    });
  };

  function handleChangeInput(name: string, value: any) {
    if (["rlvnDataNm"].includes(name)) {
      setForm({
        ...form,
        [name]: value,
      });
    }
  }

  function validationForm(param: any) {
    let check: boolean = true;

    if (!param.rlvnDataNm || !param.bizPlndId) {
      check = false;
    }

    if (check) {
      setForm((prevState: any) => {
        return {
          ...prevState,
          bizPlndId: prevState.bizId ?? null,
          rlvnDataNm: prevState.rlvnDataNm ?? null,
          explCtt: prevState.explCtt ?? null
        };
      });
    }
    return check;
  }

  function handleChangeSelect(name: string, select: any) {
    if (["bizPlndId"].includes(name)) {
      setForm({
        ...form,
        [name]: select.target.value,
      });
    } else {
      setForm({
        ...form,
        [name]: select.target.value,
      });
    }
  }

  const handleClickRegist = () => {
    dispatch(
      openModal({
        path: "/cp/monitoring/save",
        target: AppAlert,
        wrapStyle: { width: "20rem" },
        confirmProps: {
          type: "save",
          title: "저장하시겠습니까?",
          onConfirm: saveForm,
        },
      })
    );
  };

  async function saveForm() {
    if (validationForm(form)) {

      dispatch(showAndHideSpinner(true));
      const formData: FormData = new FormData();

      const data = JSON.stringify(form);
      formData.append("data", data);

      if (newFiles.length > 0) {
        for (let i = 0; i < newFiles.length; i++) {
          if (newFiles[i]) {
            formData.append("files", newFiles[i]);
          }
        }
      }

      const response = await MhdlgService.getInstance().saveDocMonitoring(formData);
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          toast.success(t("common.message.success.save", { title: "" }));
          const id = response.data.docId;
          navigate(`/cp/reduce/history/monitoring/detail/${id}`);
        }
      }
      dispatch(showAndHideSpinner(false));
    } else {
      toast.error("필수항목을 모두 입력해주시기 바랍니다.");
    }
  };

  return (
    <>
      <div className="col-wrap">
        <section className="section active">
          <div className="tab-st-box1">
            <ul className="tab-st1">
              <li>
                <a
                  href={JsxElementConstant.hrefIsNull}
                  onClick={() => {
                    navigate(`/cp/reduce/history/business`);
                  }}
                >
                  <span>사업계획서</span>
                </a>
              </li>
              <li className="on">
                <a
                  href={JsxElementConstant.hrefIsNull}
                  onClick={() => {
                    navigate(`/cp/reduce/history/monitoring`);
                  }}
                >
                  <span>모니터링</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-wrap">
            <div className="col-md-12">
              <div className="cont">
                <div className="tbl-st2 mgt20">
                  <h3 className="titT1">자료 등록</h3>

                  <table>
                    <caption>모니터링</caption>
                    <colgroup>
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "auto" }} />
                    </colgroup>
                    <tbody className="al">
                      <tr>
                        <td className="tbl-bg-g tbl-L-T2">
                          <label htmlFor={"title"} className={"no-est bu-est"} title={"*필수입력 모니터링 명"}>모니터링 명</label>
                        </td>
                        <td className="tbl-L-T2">
                          <span>
                            <ArcInput
                              inputType={"text"}
                              isRequired={true}
                              value={form.rlvnDataNm ?? ""}
                              id={"title"}
                              name={"title"}
                              onChange={(text) => handleChangeInput("rlvnDataNm", text)}
                              placeholder={"제목을 입력하세요."}
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="tbl-bg-g">
                          <label htmlFor={"content5"} className={"no-est bu-est"} title={"*필수입력 적용 사업계획서"}>적용 사업계획서</label>
                        </td>
                        <td>
                          <span>
                            <ArcSelectbox
                              name={"content5"}
                              id={"content5"}
                              options={list}
                              isRequired={false}
                              selectedValue={form.bizPlndId ?? ""}
                              onChange={(select) => handleChangeSelect("bizPlndId", select)}
                              useDefaultOption={true}
                              size={SelectboxSize.px200}
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="tbl-bg-g">
                          <label htmlFor={"txtArea-1"} title={"설명"}>설명</label>
                        </td>
                        <td>
                          <ArcTextArea value={form.explCtt || ""} rows={4} cols={40} type="w100p" isRequired={true}
                            validateMessage={"Required"} placeholder={"설명을 입력하세요."} prefix={""} maxLength={100}
                            id={"txtArea-1"} name={"txtArea-1"} onChange={handleChangeTextArea} />
                        </td>
                      </tr>
                      <tr>
                        <td className="tbl-bg-g">첨부파일</td>
                        <td>
                          <ArcFileUploader
                            maxFileNum={5}
                            maxFileSizeMb={90}
                            dbFileList={dataFiles.filter(file => file.crud !== "D")}
                            dbUpload={handleExistFile}
                            onUpload={handleClickFileAdd}
                            key={dataFiles.filter(file => file.crud !== "D").length}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="btns ar">
                  <ArcButton type={"cancel"} onClick={() => navigate(-1)} />
                  <ArcButton type={"save"} hasIcon={true} onClick={handleClickRegist} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
