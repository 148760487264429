import ArcGrid, { ArcGridCheckResult, CustomCommonCodeRenderer, GridColumn, GridEditor, GridEditorInfo } from "components/arc/ArcGrid";
import { t } from "i18next";
import { ResponseResultCode } from "model/response-api";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ActvScopService } from "services/cp/ActvScopService";
import { ComCdService } from "services/cp/ComCdService";
import { CodeUtil } from "utils/codeUtil";

export default function Scope3Grid(props: any) {
  const gridRef: any = useRef<typeof ArcGrid>();
  const [kndCd, setKndCd] = useState<any[]>([]);
  const [gridCanDraw, setGridCanDraw] = useState<boolean>(false);
  const [, setRowKey] = useState<any>(props.data.row);
  const [isDelete, setIsDelete] = useState(false);

  const month = [
    { regMm: "1", name: "1월" },
    { regMm: "2", name: "2월" },
    { regMm: "3", name: "3월" },
    { regMm: "4", name: "4월" },
    { regMm: "5", name: "5월" },
    { regMm: "6", name: "6월" },
    { regMm: "7", name: "7월" },
    { regMm: "8", name: "8월" },
    { regMm: "9", name: "9월" },
    { regMm: "10", name: "10월" },
    { regMm: "11", name: "11월" },
    { regMm: "12", name: "12월" },
  ];

  const [dataDelete, setDataDelete] = useState<any>([]);

  const checkedRow = (data: ArcGridCheckResult) => {
    setDataDelete(data.checkedRows);
  };

  const [data, setData] = useState(props.data.data);

  useEffect(() => {
    if (kndCd.length > 0) {
      if (props.data.data.length > 0) {
        setData(props.data.data);
      } else {
        getData();
        setRowKey([]);
      }
    }
  }, [props.fuflYy, props.data.data, props.scopId, kndCd]);

  function getData() {
    ActvScopService.getInstance()
      .getScop3(Number(props.fuflYy), props.scopId)
      .then((res) => {
        if (res.data.data.contents) {
          setData(res.data.data.contents);
          if (res.data.data.contents.length === 0) {
            gridDraw();
          }
        } else {
          setData([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  useEffect(() => {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_TRNSMN_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setKndCd(cdOptions);
        } else {
          setKndCd([]);
        }
      })
      .catch(() => toast.error(t("common.message.error.data")));
  }, []);

  function formatN3Name(e: any) {
    if (e.value === "Y") {
      return "출장";
    } else {
      return "통근";
    }
  }

  function addComma(value: any) {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const dataMapNumber = (e: any, name: string) => {

    let result = "";
    if (e.row.dataMap !== undefined) {
      e.row.dataMap.forEach((item: any) => {
        if (item[name]) {
          result = item[name];
        }
      })
      if (e.row.dataMap) {
        if (e.value !== null && /^[0-9.]+$/.test(e.value)) {
          return e.value !== "" ? addComma(e.value) : addComma(result);

        } else {
          return addComma(result);
        }
      }
    }
    return e.value != null ? addComma(e.value) : result;
  }

  const numberEditorEmission = (length: number, decimal: number): Partial<GridEditorInfo> => {

    return {
      options: {
        onChange: handleChangeEditor,
        type: "number",

        validation: {
          validatorFn: (value: any) => {
            return (
              (gridRef.current
                && (gridRef.current.getInstance().getData().length > 0)
              ) || value != null || value === "" || value == null
            )
          },
          regExp:
            new RegExp(`^\\d{0,${length - decimal}}(\\.\\d{0,${decimal}})?$`),
          regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
            len: length,
            dec: decimal
          })
        }
      },
    }
  }

  // 그리드 컬럼
  const defaultGridColumns = (): GridColumn[] => [

    {
      header: "mngId",
      name: "mngId",
      hidden: true,
    },
    {
      header: "구분 (통근/출장)",
      name: "n3Name",
      align: "center",
      whiteSpace: "normal",
      onAfterChange: (ev: any) => {
        setRowKey((prevRowKey: any) => {
          const newRowKeys = [...prevRowKey, ev.rowKey];
          const updatedData = gridRef.current?.getInstance().getData();
          props.onData({
            data: updatedData,
            row: newRowKeys
          });
          return newRowKeys;
        });
      },
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          return formatN3Name(e);
        }
        return "";
      },
    },
    {
      header: "교통수단",
      name: "kndCd",
      align: "center",
      whiteSpace: "normal",
      onAfterChange: (ev: any) => {
        setRowKey((prevRowKey: any) => {
          const newRowKeys = [...prevRowKey, ev.rowKey];
          const updatedData = gridRef.current?.getInstance().getData();
          props.onData({
            data: updatedData,
            row: newRowKeys
          });
          return newRowKeys;
        });
      },
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          return e.value?.comCdExpl;
        }
        return "";
      },
    },
    {
      header: "연료 종류",
      name: "fuelName",
      align: "center",
      whiteSpace: "normal",
      onAfterChange: (ev: any) => {
        setRowKey((prevRowKey: any) => {
          const newRowKeys = [...prevRowKey, ev.rowKey];
          const updatedData = gridRef.current?.getInstance().getData();
          props.onData({
            data: updatedData,
            row: newRowKeys
          });
          return newRowKeys;
        });
      },
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          return e.value;
        }
        return "";
      },
    },
    {
      header: "단위",
      name: "unitName",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return "L";
      },
    },

  ];

  //그리드 수정
  const defaultGridEditor = (): GridEditor => ({
    requestBtn: props.scopUse === "N" ? [] : ['ADD', 'DELETE'],

    isDataHotSwap: false,
    defaultRowDataSet: {
      fuflYy: (props.fuflYy ?? "").toString(),
      scopId: (props.scopId ?? "").toString(),
    },
    editableColumns: props.scopUse === "N" ?
      []
      :
      [
        {
          columnName: "n3Name",
          editorType: "select",
          options: {
            onChange: handleChangeEditor,
            listItems: [
              { text: "통근", value: "N" },
              { text: "출장", value: "Y" },
            ],
            validation: {
              dataType: "string",
              require: true,
              validatorFn: (value: any, row: any, columnNm: any) => {

                if (value === null) return false;

                // If row.kndCd is defined, perform additional validation
                if (row.kndCd) {
                  // Ensure gridRef.current and grid instance are defined
                  let dataGrid = [];

                  dataGrid = data

                  const gridInstance = gridRef.current?.getInstance();
                  if (gridInstance) dataGrid = gridInstance.getData();


                  if (!Array.isArray(dataGrid) || dataGrid.length === 0) return false;

                  // Check for the specific conditions in the data
                  const matchingItems = dataGrid.filter((d: any) =>
                    d.rowKey !== row.rowKey &&
                    d.kndCd?.comCd === row.kndCd.comCd &&
                    d.n3Name === value &&
                    d.fuelName === row.fuelName
                  );
                  return matchingItems && matchingItems.length < 1;
                }

                // If row.kndCd is not defined, return true if value is not null
                return value !== null;
              }

            },
          },
        },
        {
          columnName: "kndCd",
          editorType: CustomCommonCodeRenderer,
          options: {
            comCd: kndCd,
            onChange: handleChangeEditor,
            validation: {
              required: true,
              validatorFn: (value: any, row: any, columnNm: any) => {

                if (value === null || value === undefined) return false;
                if (value?.comCdExpl === undefined) return false;
                let dataGrid = [];

                dataGrid = data

                const gridInstance = gridRef.current?.getInstance();
                if (gridInstance) dataGrid = gridInstance.getData();

                if (!Array.isArray(dataGrid) || dataGrid.length === 0) return false;

                const matchingItems = dataGrid.filter((e: any) => e.rowKey !== row.rowKey && e.kndCd && e.kndCd.comCd === value.comCd && e.n3Name === row.n3Name && e.fuelName === row.fuelName)
                return matchingItems && matchingItems.length < 1;

              }
            },
          },
        },
        {
          columnName: "fuelName",
          editorType: "select",
          options: {
            listItems: [
              { text: "휘발유", value: "휘발유" },
              { text: "경유", value: "경유" },
              { text: "LPG", value: "LPG" },
            ],
            onChange: (e: any) => {
              handleChangeEditor(e);
            },
            validation: {
              required: true,
              validatorFn: (value: any, row: any, columnNm: any) => {
                // Check if value is null
                if (value === null) return false;

                // If row.kndCd is defined, perform additional validation
                if (row.kndCd) {
                  // Ensure gridRef.current and grid instance are defined
                  let dataGrid = [];

                  dataGrid = data

                  const gridInstance = gridRef.current?.getInstance();
                  if (gridInstance) dataGrid = gridInstance.getData();

                  if (!Array.isArray(dataGrid) || dataGrid.length === 0) return false;

                  // Check for the specific conditions in the data
                  const matchingItems = dataGrid.filter((d: any) =>
                    d.rowKey !== row.rowKey &&
                    d.kndCd?.comCd === row.kndCd.comCd &&
                    d.n3Name === row.n3Name &&
                    d.fuelName === value
                  );
                  return matchingItems && matchingItems.length < 1;
                }

                // If row.kndCd is not defined, return true if value is not null
                return value !== null;
              }
            },
          },
        },

      ],
  });

  const [gridEditor, setGridEditor] = useState<GridEditor>(defaultGridEditor);
  const [gridColumns, setGridColumns] = useState<GridColumn[]>(defaultGridColumns);

  useEffect(() => {

    if ((data.length > 0 && kndCd.length > 0)) {
      gridDraw();
    }

  }, [props.fuflYy, kndCd, data, props.scopUse]);

  const gridDraw = () => {
    const columns = defaultGridColumns();
    const gridEditor = defaultGridEditor();
    month.forEach(f => {
      columns.push({
        header: f.name,
        name: `mm${f.regMm}EmsnQnt`,
        align: "right",
        whiteSpace: "normal",
        onAfterChange: (ev: any) => {
          setRowKey((prevRowKey: any) => {
            const newRowKeys = [...prevRowKey, ev.rowKey];
            const updatedData = gridRef.current?.getInstance().getData();
            props.onData({
              data: updatedData,
              row: newRowKeys
            });
            return newRowKeys;
          });
        },
        formatter: (e: any) => {
          return dataMapNumber(e, `mm${f.regMm}EmsnQnt`)
        },
      })
    });

    if (props.scopUse !== "N") {
      month.forEach((f, i) => {
        gridEditor.editableColumns.push({
          columnName: `mm${f.regMm}EmsnQnt`,
          editorType: "text",
          ...numberEditorEmission(15, 4)
        });
      })
    }

    setGridColumns(columns);
    setGridEditor(gridEditor);
    setGridCanDraw(true);
  }

  // 그리드 수정함수
  function handleChangeEditor(e: any) {
  }

  const handleDataFromChild = (event: any) => {
    setIsDelete(event);
  };

  useEffect(() => {
    if (isDelete) {
      let checkedNum = dataDelete.length;
      if (checkedNum > 0) {
        handleDel();
      } else {
        toast.error("체크된 항목이 없습니다.");
      }
    }
    return () => {
      setIsDelete(false);
    };
  }, [isDelete])

  async function handleDel() {
    const dataDel = dataDelete.filter((item: any) => item.mngId !== null);
    if (dataDel.length > 0) {
      try {
        const response = await ActvScopService.getInstance().deleteActvScopList(dataDel);
        if (response) {
          if (ResponseResultCode.Y === response.resultCode) {
            toast.success(t('common.message.success.delete', { title: t('') }));
            props.onData({ row: [], data: [] })
          } else {
            toast.error(t('common.message.error.data'));
          }
        } else {
          toast.error(t('common.message.error.system'));
        }
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  }

  const makeGrid = useCallback(() => {
    if (gridCanDraw) {
      return (
        <ArcGrid
          gridRef={gridRef}
          key={`${kndCd.length}`}
          data={data}
          useStaticData={true}
          columns={gridColumns}
          rowHeaders={["checkbox"]}
          usePagination={false}
          editor={gridEditor}
          onCheckRow={checkedRow}
          isTest={true}
          complexHeader={{ complexColumns: [], height: 70 }}
          onDelete={handleDataFromChild}
        />
      );
    }
  }, [gridCanDraw, gridColumns, data, kndCd]);

  return (
    <div className="fs14">
      {makeGrid()}
    </div>
  );
}
