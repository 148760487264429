import ArcButton, {
  ButtonColor,
  ButtonIcon,
  ButtonShape,
  ButtonSize
} from "components/arc/ArcButton";
import React, {useEffect, useRef, useState} from "react";
import ArcGrid, {
  CustomSelectCommonCodeRenderer,
  GridColumn,
  GridEditor
} from "components/arc/ArcGrid";
import {useTranslation} from "react-i18next";
import ArcFileUploader from "components/arc/ArcFileUploader";
import {FileInfoModel} from "model/common/fileInfoModel";
import {useLocation, useNavigate} from "react-router-dom";
import ArcInput from "components/arc/ArcInput";
import {useDaumPostcodePopup} from "react-daum-postcode";
import ArcSelectbox, {SelectboxSize} from "components/arc/ArcSelectbox";
import ArcCheckbox from "components/arc/ArcCheckbox";
import {CustRegRqsModul} from "model/ua/CustRegRqsModul";
import {useAppSelector} from "stores/hook";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {openModal} from "reducers/modalSlice";
import AppAlert from "components/common/AppAlert";
import {SignUpService} from "services/signUp/SignUpService";
import {UserService} from "services/cp/UserService";
import {setSignUpInfo} from "reducers/signUpSlice";

export default function SignUpTab3() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(src);
  const {state} = useLocation();
  const userForm = useAppSelector((state) => state.signUp.signUpInfo) || [];
  const comCd = useAppSelector((state) => state.signUp.signUpComCd);
  const [formSignList, setFormSignList] = useState<any[]>([]);
  const [deleteFile, setDeleteFile] = useState<any>([]);
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState<boolean>(false);
  const refConfirm = useRef(false);

  const [dataFiles, setDataFiles] = useState<any>([]);
  const [tempFiles, setTempFiles] = useState<any>([]);

  const [gridSave, setGridSave] = useState(false);
  const [keyValue, setKeyValue] = useState<any>();

  const [unitCd, setUnitCd] = useState<any[]>(comCd.ComUnit.filter((a: any) => a.label === "kWh" || a.label === "kW"))
  const [emksRsrcClCd, setEmksRsrcClCd] = useState<any[]>(comCd.EmksRsrcClCd)
  const [emksEqpKndCdUnitCd, setEmksEqpKndCdUnitCd] = useState<any[]>(comCd.EmksEqpKndCd)
  const [emksPrtclKndCdUnitCd, setEmksPrtclKndCdUnitCd] = useState<any[]>(comCd.EmksPrtclKndCd)
  const [emksIndsCmplxClCdOption, setEmksIndsCmplxClCdOption] = useState<any[]>(comCd.EmksIndsCmplxClCd)
  const [emksCustTpClCdOption, setEmksCustTpClCdOption] = useState<any[]>(comCd.EmksCustTpClCd)
  const [repNumSelect, setRepNumSelect] = useState<any[]>(comCd.MobileNumberPrefix)
  const unitYN = [
    {label: "Y", value: "Y"},
    {label: "N", value: "N"},
  ]

  const gridRefs = useRef<Array<any>>([]);
  if (gridRefs?.current?.length !== formSignList?.length) {
    gridRefs.current = formSignList?.map((_, i) => gridRefs.current[i] || React.createRef());
  }

  const addNewForm = () => {
    setFormSignList([...formSignList, new CustRegRqsModul()]);
    dataFiles.push([]);
    tempFiles.push([])
  };

  useEffect(() => {
    UserService.getInstance().getComcd()
    .then((res: any) => {
      setUnitCd(res.data.data.ComUnit.filter((a: any) => a.label === "kWh" || a.label === "kW"));
      setEmksRsrcClCd(res.data.data.EmksRsrcClCd)
      setEmksEqpKndCdUnitCd(res.data.data.EmksEqpKndCd);
      setEmksPrtclKndCdUnitCd(res.data.data.EmksPrtclKndCd);
      setEmksIndsCmplxClCdOption([{label: "선택하세요.", value: null}, ...res.data.data.EmksIndsCmplxClCd]);
      setEmksCustTpClCdOption([{label: "선택하세요.", value: null}, ...res.data.data.EmksCustTpClCd]);
      setRepNumSelect([{label: "선택하세요.", value: null},...res.data.data.MobileNumberPrefix])

    })
  }, []);

  useEffect(() => {
    if (userForm.custRegRqss === undefined || userForm.custRegRqss.length ===0) {
      if (formSignList.length === 0 && (unitCd.length !== 0 || emksRsrcClCd.length !== 0 || emksEqpKndCdUnitCd.length !== 0 || emksPrtclKndCdUnitCd.length !== 0 || emksIndsCmplxClCdOption.length !== 0 || emksCustTpClCdOption.length !== 0 || repNumSelect.length !== 0)) {
        addNewForm();
      }
    } else {
      const dbFile: any[] = [];
      const loadSignList = userForm?.custRegRqss?.map((item: any, index: number) => {
        const [dtlsAddrOne, dtlsAddrTwo] = item?.addr?.split('@@@@') || ["", ""];
        const [telNoFi, telNoSe, telNoTh] = item?.telNo?.split('-') || ["", "", ""];
        dbFile[index] = [];
        const convertRsrcList: any[] = [];
        item.custRegRqsRsrcs.map((rsrc: any) => {
          if (rsrc.flxRsrcCtrlPsblYn == "Y" || rsrc.flxRsrcInstmPsblYn == "Y") {
            setConfirm(true);
          }
          convertRsrcList.push({
            rsrcId: rsrc.rsrcId,
            emksRsrcChgCd: rsrc.emksRsrcChgCd,
            emksRsrcClCd: {
              comCdExpl: emksRsrcClCd.find(cd => cd.value == rsrc.emksRsrcClCd)?.label,
              comCd: rsrc.emksRsrcClCd
            },
            emksEqpKndCd: {
              comCdExpl: emksEqpKndCdUnitCd.find(cd => cd.value == rsrc.emksEqpKndCd)?.label,
              comCd: rsrc.emksEqpKndCd
            },
            emksPrtclKndCd: {
              comCdExpl: emksPrtclKndCdUnitCd.find(cd => cd.value == rsrc.emksPrtclKndCd)?.label,
              comCd: rsrc.emksPrtclKndCd
            },
            flxRsrcCpctUnitId: {
              comCdExpl: unitCd.find(cd => cd?.value == rsrc.flxRsrcCpctUnitId)?.label,
              comCd: rsrc.flxRsrcCpctUnitId
            },
            trsfmEqpCpctUnitId: {
              comCdExpl: unitCd.find(cd => cd?.value == rsrc.trsfmEqpCpctUnitId)?.label,
              comCd: rsrc.trsfmEqpCpctUnitId
            },
            flxRsrcNm: rsrc.flxRsrcNm,
            flxRsrcCpct: rsrc.flxRsrcCpct,
            flxRsrcInstmPsblYn: {
              comCdExpl: unitYN.find(cd => cd.value == rsrc.flxRsrcInstmPsblYn)?.label,
              comCd: rsrc.flxRsrcInstmPsblYn
            },
            flxRsrcCtrlPsblYn: {
              comCdExpl: unitYN.find(cd => cd.value == rsrc.flxRsrcCtrlPsblYn)?.label,
              comCd: rsrc.flxRsrcCtrlPsblYn
            },
            eqpCpct: rsrc.eqpCpct,
            msinTpNm: rsrc.msinTpNm,
            msinNm: rsrc.msinNm,
            ipAddr: rsrc.ipAddr,
            eqpPortNo: rsrc.eqpPortNo,
            mgMetrId: rsrc.mgMetrId,
            actnCfnsYn: rsrc.actnCfnsYn,
            actnCfnsDt: rsrc.actnCfnsDt,
            regRsltYn: rsrc.regRsltYn,
            regFailrRsn: rsrc.regFailrRsn,
            dbRegDt: rsrc.dbRegDt,
          });
        });
        return {
          ...item,
          custRegRqsRsrcs: convertRsrcList,
          dtlsAddrOne: dtlsAddrOne.trim(),
          dtlsAddrTwo: dtlsAddrTwo?.trim() || "",
          telNoFi: telNoFi,
          telNoSe: telNoSe,
          telNoTh: telNoTh,
          file: {
            files: item.fileInfos?.map((filesInfo: any) => {
              const fileData = {
                name: filesInfo.rlFileNm,
                size: filesInfo.fileCpct,
                fileId: filesInfo.atflId
              };
              dbFile[index].push(fileData);
              return fileData;
            })
          }
        };
      });
      if (userForm?.dataFile?.length > 0) {
        const newData = [...userForm.dataFile];
        const filteredDbFile = dbFile.filter((item: any) => {
          return !userForm.deleteFile.some((deleteItem: any) => deleteItem.fileId === item.fileId);
        });
        setDataFiles(filteredDbFile);
        setTempFiles(newData)
      } else {
        setDataFiles(dbFile);
        setTempFiles(dbFile.map(()=>[]))
      }
      setFormSignList(loadSignList);
    }
  }, [unitCd, emksRsrcClCd, emksEqpKndCdUnitCd, emksPrtclKndCdUnitCd, emksIndsCmplxClCdOption, emksCustTpClCdOption, repNumSelect]);


  useEffect(() => {
    if (confirm) {
      refConfirm.current = confirm;
    }
  }, [confirm]);


  const [check1, setCheck1] = useState(false)

  const [openIndex, setOpenIndex] = useState(null);
  const toggleOpen = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const clickCheck1 = (isChecked: any) => {
    setCheck1(isChecked)
  }

  const removeForm = (index: number) => {
    let newArray = formSignList.filter((_, i) => i !== index);
    tempFiles.map((item: any) => {
      return item ?? [];
    });
    const newDataFiles = dataFiles?.filter((item:any,index2:any)=>index !== index2)
    const newTempFiles = tempFiles?.filter((item:any,index2:any)=>index !== index2)

    setDataFiles(newDataFiles);
    setTempFiles(newTempFiles);
    setFormSignList(newArray);
    setKeyValue(crypto.randomUUID());
    gridRefs.current.splice(index, 1);
  };

  const checkedRow = () => {};

  const handleClick = (index: number) => {
    open({
      onComplete: data => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
          }
          fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        if (index !== null) {
          setFormSignList((prevState) => {
            return prevState.map((form, i) =>
              i === index ? {...form, dtlsAddrOne: fullAddress} : form
            );
          });
        }
      }
    });
  };

  const handleChangeInput = (index: number, field: string, value: any) => {
    const updatedForms = formSignList.map((form, i) =>
        i === index ? {...form, [field]: value} : form
    );
    setFormSignList(updatedForms);
  };

  function setInitialDataFiles() {
    let files = [];
    if (state) {
      files = state.file?.files ?? [];
    }
    if (files?.length > 0) {
      return files.map((m: FileInfoModel) => {
        return {name: m.realNm, size: m.size, fileId: m.fileId};
      });
    } else {
      return [];
    }
  }

  function handleClickFileAdd(index: any, files: File[]) {
    const newFormSignList = [...formSignList];
    newFormSignList[index].file = {
      ...newFormSignList[index].file,
      files: files,
    };

    setTempFiles(() => {
      const updatedDataFiles = [...tempFiles];
      updatedDataFiles[index] = [];
      updatedDataFiles[index] = (updatedDataFiles[index] || []).concat(files);
      return updatedDataFiles;
    });

    setFormSignList(newFormSignList);
  }

  function handleExistFile(index: any, files: any) {
    let deleteFiles: any = deleteFile;
    const formFiles = dataFiles[index]?.map((item: any) => {
      return item.fileId
    });

    const haveFiles = files.map((a: any) => a.fileId);
    const dele = formFiles.filter((item: any) => !haveFiles.includes(item));

    if (deleteFiles.length < 1) {
      deleteFiles = dele;
    } else {
      deleteFiles = deleteFiles.concat(dele);
    }
    deleteFiles = Array.from(new Set(deleteFiles));
    setDeleteFile(deleteFiles);
  }


  const handleConfirm = () => {
    setConfirm(true)
  }

  const rsrcGridColumns: GridColumn[] = [
    {
      header: "flxRsrcId",
      name: `flxRsrcId`,
      hidden: true
    },
    {
      header: "자원명",
      align: "center",
      name: `flxRsrcNm`,
      formatter: function (e: any) {
        return e.value;
      },
    },
    {
      header: "자원종류",
      align: "center",
      name: `emksRsrcClCd`,
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "자원용량",
      align: "center",
      name: `flxRsrcCpct`,
      formatter: function (e: any) {
        return e.value;
      },
    },
    {
      header: "단위",
      align: "center",
      name: `flxRsrcCpctUnitId`,
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "계측가능여부",
      align: "center",
      name: `flxRsrcInstmPsblYn`,
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "제어가능여부",
      align: "center",
      name: `flxRsrcCtrlPsblYn`,
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "장치타입",
      align: "center",
      name: `emksEqpKndCd`,
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "장치용량",
      align: "center",
      name: `eqpCpct`,
      formatter: function (e: any) {
        return e.value;
      },
    },
    {
      header: "단위",
      align: "center",
      name: "trsfmEqpCpctUnitId",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "계측기유형명",
      align: "center",
      name: `msinTpNm`,
    },
    {
      header: "프로토콜",
      align: "center",
      name: `emksPrtclKndCd`,
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "계측기명",
      align: "center",
      name: `msinNm`,
    },
    {
      header: "IP주소",
      align: "center",
      name: `ipAddr`,
    },
    {
      header: "장치포트번호",
      align: "center",
      name: `eqpPortNo`,
    },
  ];


  const rsrcGridEditor: GridEditor = {
    requestBtn: ['ADD', 'DELETE'],
    isDataHotSwap: false,
    defaultRowDataSet: {
      flxRsrcNm: "",
      flxRsrcCpct: "",
      emksRsrcClCd: "",
      flxRsrcCpctUnitId: "",
      flxRsrcInstmPsblYn: "",
      flxRsrcCtrlPsblYn: "",
      emksEqpKndCd: "",
      eqpCpct: "",
      trsfmEqpCpctUnitId: "",
      msinTpNm: "",
      emksPrtclKndCd: "",
      msinNm: "",
      ipAddr: "",
      eqpPortNo: "",
    },
    editableColumns: [
      {
        columnName: "flxRsrcId",
        editorType: "text",
        options: {
          validation: {
            dataType: "string",
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "flxRsrcNm",
        editorType: "text",
        options: {
          validation: {
            dataType: "string",
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "flxRsrcCpct",
        editorType: "text",
        options: {
          validation: {
            validatorFn: (value: any) => {
              return (
                  value != null || value === "" || value == null
              )
            },
            regExp:
                new RegExp(`^\\d{0,${15 - 0}}(\\.\\d{0,${0}})?$`),
            regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
              len: 15,
              dec: 0
            })
          }
        },
      },
      {
        columnName: "emksRsrcClCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: emksRsrcClCd,
          validation: {
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "flxRsrcCpctUnitId",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitCd,
          validation: {
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "flxRsrcInstmPsblYn",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitYN,
          validation: {
            validatorFn: (value: any) => {
              if (value?.comCd == "Y" && refConfirm.current === false) {
                dispatch(openModal({
                  path: '/auth/signUp3',
                  target: AppAlert,
                  confirmProps: {
                    type: 'confirm',
                    title: '정보 동의',
                    message: '정보 동의를 원하신경우 확인을 눌러주세요.',
                    onConfirm: handleConfirm,
                    onClose: () => {
                      setConfirm(false)
                    }
                  }
                }));
              }
              return true;
            }
          },
        },
      },
      {
        columnName: "flxRsrcCtrlPsblYn",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitYN,
          validation: {
            validatorFn: (value: any) => {
              if (value?.comCd == "Y" && refConfirm.current === false) {
                dispatch(openModal({
                  path: '/auth/signUp3',
                  target: AppAlert,
                  confirmProps: {
                    type: 'confirm',
                    title: '정보 동의',
                    message: '정보 동의를 원하신경우 확인을 눌러주세요.',
                    onConfirm: handleConfirm,
                    onClose: () => {
                      setConfirm(false)
                    }
                  }
                }));
              }
              return true;
            }
          },
        },
      },
      {
        columnName: "emksEqpKndCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: emksEqpKndCdUnitCd,
          validation: {
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "eqpCpct",
        editorType: "text",
        options: {
          validation: {
            validatorFn: (value: any) => {
              return (
                  value != null || value === "" || value == null
              )
            },
            regExp:
                new RegExp(`^\\d{0,${15 - 4}}(\\.\\d{0,${4}})?$`),
            regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
              len: 15,
              dec: 4
            })
          }
        },
      },
      {
        columnName: "trsfmEqpCpctUnitId",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitCd,
          validation: {
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "msinTpNm",
        editorType: "text",
        options: {
          validation: {
            dataType: "string",
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "emksPrtclKndCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: emksPrtclKndCdUnitCd,
          validation: {
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "msinNm",
        editorType: "text",
        options: {
          validation: {
            dataType: "string",
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "ipAddr",
        editorType: "text",
        options: {
          validation: {
            dataType: "string",
            validatorFn: (value: any) => {
              return true;
            }
          },
        },
      },
      {
        columnName: "eqpPortNo",
        editorType: "text",
        options: {
          validation: {
            validatorFn: (value: any) => {
              return (
                  value != null || value === "" || value == null
              )
            },
            regExp:
                new RegExp(`^\\d{0,${15 - 4}}(\\.\\d{0,${4}})?$`),
            regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
              len: 15,
              dec: 4
            })
          }
        },
      },
    ],
  };

  const saveYN = (YN: any) => {
    formSignList.map((item, index) => {
      if (item.dtlsAddrOne != undefined && item.dtlsAddrTwo != undefined) {
        if(item.dtlsAddrOne !==""){
          item.addr = item.dtlsAddrOne.trim() + "@@@@" + item.dtlsAddrTwo.trim();
        }else{
          item.addr = ""
        }
      }
      if (item.telNoFi != undefined && item.telNoSe != undefined && item.telNoTh != undefined) {
        item.telNo = item.telNoFi + "-" + item.telNoSe + "-" + item.telNoTh;
      }
      item.emksRegRqsCd="C";

      item.custRegRqsRsrcs = gridRefs.current[index].current.getInstance().getData();
      item.custRegRqsRsrcs.map((array: any) => {
        array.trsfmEqpCpctUnitId = array?.trsfmEqpCpctUnitId?.comCd;
        array.emksEqpKndCd = array?.emksEqpKndCd?.comCd;
        array.emksPrtclKndCd = array?.emksPrtclKndCd?.comCd;
        array.emksRsrcClCd = array?.emksRsrcClCd?.comCd;
        array.flxRsrcCpctUnitId = array?.flxRsrcCpctUnitId?.comCd;
        array.flxRsrcInstmPsblYn = array?.flxRsrcInstmPsblYn?.comCd;
        array.flxRsrcCtrlPsblYn = array?.flxRsrcCtrlPsblYn?.comCd;
        array.emksRsrcChgCd = "C";
      })

      item.custRegRqsRsrcsRequests = gridRefs.current[index].current.getInstance().getData();
      item.custRegRqsRsrcsRequests.map((array: any) => {
        array.trsfmEqpCpctUnitId = array?.trsfmEqpCpctUnitId?.comCd;
        array.emksEqpKndCd = array?.emksEqpKndCd?.comCd;
        array.emksPrtclKndCd = array?.emksPrtclKndCd?.comCd;
        array.emksRsrcClCd = array?.emksRsrcClCd?.comCd;
        array.flxRsrcCpctUnitId = array?.flxRsrcCpctUnitId?.comCd;
        array.flxRsrcInstmPsblYn = array?.flxRsrcInstmPsblYn?.comCd;
        array.flxRsrcCtrlPsblYn = array?.flxRsrcCtrlPsblYn?.comCd;
        array.emksRsrcChgCd = "C";
      })
      return item;
    })
    const updatedUserForm = {
      ...userForm,
      deleteFile: deleteFile,
      dataFile: tempFiles,
      userTmpSavYn: YN,
      custRegRqss: formSignList,
    };

    if (YN !== "Y" && YN !== "N") {
      dispatch(setSignUpInfo(updatedUserForm))
      navigate(`/sign-up/2`);
    } else {
      SignUpService.getInstance().insertSignUp2(updatedUserForm, formSignList)
      .then((res: any) => {
        if (res?.resultCode == "Y" && YN == "N") {
          navigate(`/sign-up/5`);
        } else if (res?.resultCode == "Y" && YN == "Y") {
          toast.success(t("임시저장되었습니다."));
        } else {
          toast.error(t("common.message.error.system"));
        }
      });
    }
    setGridSave(false)
  }

  return (
    <section className="section active">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont member-h">
            <div className="tit-step">
              <h1 className="titT2">회원가입</h1>
              <ul className="tab-order-st1">
                <li>
                  <div><strong>01</strong><span>본인인증</span></div>
                </li>
                <li>
                  <div><strong>02</strong><span>기본정보</span></div>
                </li>
                <li className="on">
                  <div><strong>03</strong><span>고객정보</span></div>
                </li>
                <li>
                  <div><strong>04</strong><span>가입완료</span></div>
                </li>
              </ul>
            </div>
            <div className="join-box join-w3">
              <h2 className="titT1">약관동의</h2>
              <p className="desc">회원가입을 위해 이용약관 및 개인정보 수집 및 이용에 관한 안내를 읽고 동의해주세요.</p>
              <div className="accodian">
                <ul>
                  <li>
                    <div className="acc-tit">
                    <span className="checkbox-radio-type02">
                      <ArcCheckbox onChecked={clickCheck1} text={"(필수) 서비스 이용약관"} id={"1"}
                                   name={"1"} checked={check1}/>
                    </span>
                      <button className={`tog-btn ${openIndex === 0 ? 'open' : ''}`}
                              onClick={() => toggleOpen(0)}></button>
                    </div>
                    <div className="claus"
                         style={{display: openIndex === 0 ? 'block' : 'none'}}>
                      <span>서비스 이용약관</span>
                      <ul>
                        <li>1. 제1조 (목적)</li>
                        <li>2. 본 약관은 한국전력공사(이하 ‘회사’라 한다.)가 운영하는 에너지클라우드 웹사이트의 서비스를 이용함에 있어
                          발생하는 회사와 회원의 서비스 이용조건 및 절차, 권리와 의무, 기타 필요한 제반사항의 규정을 목적으로 합니다.
                        </li>
                        <li>3. 제2조 (이용약관의 효력 및 변경)
                          <ul>
                            <li>a. 1&#41;이 약관은 에너지클라우드 웹사이트에 게시하거나 전자우편의 방법으로 회원에게 공지됨으로써 그
                              효력이 발생됩니다.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="join-box join-w3">
              <div className="mgt15 client-info-con">
                <h2>고객정보</h2>
                <div className="flexWrap">
                  <p className="desc">사업자 정보 내용을 입력해주세요.</p>
                  <span className="bu-est"><strong>은 필수 항목입니다.</strong></span>
                </div>

                {formSignList?.map((form, index) => (
                    <div className="client-cont">
                      <div  className="flexWrap mgb0 mgt15">
                        <h3>고객정보 {index + 1}</h3>
                        <ArcButton
                            onClick={() => removeForm(index)}
                            type={"custom"}
                            hasIcon={false}
                            size={"btn-small"}
                            color={ButtonColor.btn1}
                            shape={ButtonShape.round}
                            text={"삭제"}
                        />
                      </div>
                      <div className="inp-flex-con">
                        <div className="flex-col">
                          <div className="inp-flex">
                            <label htmlFor={`csthsNm-${index}`} className="bu-est" title={"*필수입력 고객명"}>고객명</label>
                            <ArcInput
                                key={keyValue}
                                type={"w50p"}
                                value={form.csthsNm ?? ""}
                                id={`csthsNm-${index}`}
                                inputType={"text"}
                                displayTitle={false}
                                isRequired={true}
                                onChange={(value) => handleChangeInput(index, "csthsNm", value)}
                                placeholder={"고객명을 입력하세요"}
                                maxLength={500}
                            />
                          </div>
                          <div className="inp-flex">
                            <label htmlFor={`kepcoCustNo-${index}`} className="bu-est" title={"*필수입력 고객번호"}>고객번호</label>
                            <ArcInput
                                key={keyValue}
                                type={"w50p"}
                                value={form.kepcoCustNo || ""}
                                id={`kepcoCustNo-${index}`}
                                inputType={"text"}
                                displayTitle={false}
                                isRequired={true}
                                onChange={(value) => handleChangeInput(index, "kepcoCustNo", value)}
                                placeholder={"고객번호를 입력하세요"}
                                maxLength={500}
                            />
                          </div>
                        </div>
                        <div className="flex-col">
                          <div className="inp-flex">
                            <label htmlFor={`telNo-${index}`} className="bu-est" title={"*필수입력 전화번호"}>전화번호</label>
                            <ArcSelectbox key={keyValue}
                                          name={"telNo"}
                                          id={`telNo-${index}`}
                                          isRequired={true}
                                          selectedValue={form.telNoFi ?? ""}
                                          onChange={(value) => {
                                            handleChangeInput(index, "telNoFi", value.target.value)
                                          }}
                                          options={repNumSelect}
                                          size={SelectboxSize.px100}
                            />
                            <span className="input-util mgl10 mgr10">-</span>
                            <ArcInput
                                isRequired={true}
                                value={form.telNoSe ?? ""}
                                inputType={"tel"}
                                maxLength={4}
                                minValue={0}
                                maxValue={9999}
                                type={"w100"}
                                disabled={false}
                                readonly={false}
                                placeholder={"0000"}
                                prefix={""}
                                id={"input-9"}
                                name={"input-9"}
                                displayTitle={false}
                                onChange={(value) => {
                                  handleChangeInput(index, "telNoSe", value)
                                }}
                            />
                            <span className="input-util mgl10 mgr10">-</span>
                            <ArcInput
                                isRequired={true}
                                value={form.telNoTh ?? ""}
                                inputType={"tel"}
                                maxLength={4}
                                minValue={0}
                                maxValue={9999}
                                type={"w100"}
                                disabled={false}
                                readonly={false}
                                placeholder={"0000"}
                                prefix={""}
                                id={"input-10"}
                                name={"input-10"}
                                displayTitle={false}
                                onChange={(value) => {
                                  handleChangeInput(index, "telNoTh", value)
                                }}
                            />
                          </div>
                          <div className="inp-flex">
                            <label htmlFor={`cntrPwr-${index}`} className="bu-est" title={"*필수입력 계약전력"}>계약전력</label>
                            <ArcInput
                                key={keyValue}
                                type={"w50p"}
                                value={form.cntrPwr ?? ""}
                                id={`cntrPwr-${index}`}
                                inputType={"number"}
                                displayTitle={false}
                                isRequired={true}
                                onChange={(value) => handleChangeInput(index, "cntrPwr", value)}
                                placeholder={"계약전력을 입력하세요"}
                                maxLength={500}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inp-flex-con">
                        <div className="flex-col">
                          <div className="inp-flex">
                            <label htmlFor={`emksCustTpClCd-${index}`} className="bu-est" title={"*필수입력 고객유형"}>고객유형</label>
                            <ArcSelectbox
                                key={keyValue}
                                name={"고객유형"}
                                id={`emksCustTpClCd-${index}`}
                                title={"고객유형 선택"}
                                selectedValue={form.emksCustTpClCd ?? ""}
                                onChange={(value) => {
                                  handleChangeInput(index, "emksCustTpClCd", value.target.value)
                                }}
                                options={emksCustTpClCdOption}
                            />
                          </div>
                          <div className="inp-flex">
                            <label htmlFor={`emksIndsCmplxClCd-${index}`} className="bu-est" title={"*필수입력 단지"}>단지</label>
                            <ArcSelectbox
                                key={keyValue}
                                name={"단지"}
                                id={`emksIndsCmplxClCd-${index}`}
                                title={"단지 선택"}
                                selectedValue={form.emksIndsCmplxClCd ?? ""}
                                onChange={(value) => {
                                  handleChangeInput(index, "emksIndsCmplxClCd", value.target.value)
                                }}
                                options={emksIndsCmplxClCdOption}
                            />
                          </div>
                        </div>
                        <div className="flex-col">
                          <div className="inp-flex">
                            <label htmlFor={`addr-${index}`} className="bu-est" title={"*필수입력 고객주소"}>고객주소</label>
                            <ArcInput
                                key={keyValue}
                                type={"w50p"}
                                value={form.dtlsAddrOne ?? ""}
                                id={`addr-${index}`}
                                inputType={"text"}
                                displayTitle={false}
                                isRequired={true}
                                onChange={(value) => handleChangeInput(index, "dtlsAddrOne", value)}
                                placeholder={"고객주소를 선택하세요"}
                                readonly={true}
                                maxLength={500}
                            />
                            <ArcButton
                                type={"custom"}
                                shape={ButtonShape.round}
                                size={ButtonSize.regular}
                                color={ButtonColor.btn2}
                                text={"주소찾기"}
                                title={"새창"}
                                onClick={() => handleClick(index)}
                            />
                          </div>
                          <div className="inp-flex">
                            <label className="bu-est hid">고객주소</label>
                            <ArcInput
                                key={keyValue}
                                type={"w50p"}
                                className={"mgl15"}
                                value={form.dtlsAddrTwo ?? ""}
                                id={`dtlsAddr-${index}`}
                                inputType={"text"}
                                onChange={(value) => handleChangeInput(index, "dtlsAddrTwo", value)}
                                placeholder={"상세주소를 입력하세요"}
                                maxLength={10}
                                displayTitle={false}
                            />
                          </div>
                        </div>
                      </div>

                      <ArcGrid
                          key={keyValue}
                          gridRef={gridRefs.current[index]}
                          useStaticData={true}
                          data={formSignList[index]?.custRegRqsRsrcs}
                          columns={rsrcGridColumns}
                          editor={rsrcGridEditor}
                          rowHeaders={["checkbox"]}
                          usePagination={false}
                          useGridInfoHeader={true}
                          onCheckRow={checkedRow}
                          save={gridSave}
                          isTest={true}
                      />

                      <div className="inp-flex-con">
                        <div className="flex-col">
                          <div className="inp-flex nw">
                            <span className="no-est">기타첨부파일</span>
                            <div key={keyValue} className={"InpSel-w80"}>
                              <ArcFileUploader
                                  key={keyValue}
                                  maxFileNum={5}
                                  maxFileSizeMb={90}
                                  localFileList={(tempFiles[index]?.length == 0 || tempFiles[index]?.length == undefined) ? [] : tempFiles[index]}
                                  dbFileList={(dataFiles[index]?.length == 0 || dataFiles[index]?.length == undefined) ? [] : dataFiles[index]}
                                  dbUpload={(files) => handleExistFile(index, files)}
                                  onUpload={(files) => handleClickFileAdd(index, files)}
                              />
                            </div>
                          </div>
                          <div className="inp-flex">
                            <span className="bu-est hid"></span>
                            <p className="bu-atte">도면 파일, 계통 정보 등의 기타 파일을 첨부해 주시기 바랍니다.</p>
                          </div>
                        </div>
                      </div>
                      <hr className="line1"/>

                    </div>

                ))}

                <div className="btns ac mgt30">
                  <ArcButton
                      onClick={addNewForm}
                      type={"custom"}
                      hasIcon={false}
                      size={"btn-small"}
                      color={ButtonColor.btn3}
                      shape={ButtonShape.round}
                      text={"+고객정보추가"}
                  />
                </div>
              </div>
            </div>
            <div className="btns">
              <ArcButton type={"cancel"} hasIcon={false} size={"btn-lage"}/>
              <ArcButton type={"custom"} hasIcon={true} color={ButtonColor.btn4} text={"이전"}
                         shape={ButtonShape.normal}
                         size={"btn-lage"}
                         onClick={() => {
                           saveYN("이전");
                         }}
              />
              <ArcButton type={"custom"} hasIcon={true} icon={ButtonIcon.save}
                         color={ButtonColor.btn1} text={"임시저장"} shape={ButtonShape.normal}
                         size={"btn-lage"}
                         onClick={() => {
                           if (formSignList.every((item: any) => {
                             return item.kepcoCustNo !== null && item.kepcoCustNo !== "" && item.kepcoCustNo !== undefined;
                           })) {
                             saveYN("Y")
                           } else {
                             toast.error("고객번호를 입력하세요.")
                           }
                         }
                         }
              />
              <ArcButton type={"search"} hasIcon={false} text={"회원가입"} size={"btn-lage"}
                         onClick={() => {
                           const hasNullCsthsNm =

                               formSignList.every((item: any, index) => {
                                 item.custRegRqsRsrcsRequests = gridRefs.current[index].current.getInstance().getData();
                                 item.custRegRqsRsrcsRequests.map((array: any) => {
                                   array.trsfmEqpCpctUnitId = array?.trsfmEqpCpctUnitId?.comCd;
                                   array.emksEqpKndCd = array?.emksEqpKndCd?.comCd;
                                   array.emksPrtclKndCd = array?.emksPrtclKndCd?.comCd;
                                   array.emksRsrcClCd = array?.emksRsrcClCd?.comCd;
                                   array.flxRsrcCpctUnitId = array?.flxRsrcCpctUnitId?.comCd;
                                   array.flxRsrcInstmPsblYn = array?.flxRsrcInstmPsblYn?.comCd;
                                   array.flxRsrcCtrlPsblYn = array?.flxRsrcCtrlPsblYn?.comCd;
                                 })

                                 return item.csthsNm !== null && item.csthsNm !== "" && item.csthsNm !== undefined &&
                                     item.telNoFi !== null && item.telNoFi !== "" && item.telNoFi !== undefined &&
                                     item.telNoSe !== null && item.telNoSe !== "" && item.telNoSe !== undefined &&
                                     item.telNoTh !== null && item.telNoTh !== "" && item.telNoTh !== undefined &&
                                     item.kepcoCustNo !== null && item.kepcoCustNo !== "" && item.kepcoCustNo !== undefined &&
                                     item.cntrPwr !== null && item.cntrPwr !== "" && item.cntrPwr !== undefined && !isNaN(item.cntrPwr) &&
                                     item.emksCustTpClCd !== null && item.emksCustTpClCd !== "" && item.emksCustTpClCd !== undefined &&
                                     item.dtlsAddrOne !== null && item.dtlsAddrOne !== "" && item.dtlsAddrOne !== undefined &&
                                     item.emksIndsCmplxClCd !== null && item.emksIndsCmplxClCd !== "" && item.emksIndsCmplxClCd !== undefined &&
                                     // item.repAtflId !== null && item.repAtflId !== "" && item.repAtflId !== undefined &&  // 임시저장 파일 가져오기?

                                     item.custRegRqsRsrcsRequests.every((item2: any) => {
                                       return item2.flxRsrcCtrlPsblYn !== null && item2.flxRsrcCtrlPsblYn !== "" && item2.flxRsrcCtrlPsblYn !== undefined &&
                                           item2.trsfmEqpCpctUnitId !== null && item2.trsfmEqpCpctUnitId !== "" && item2.trsfmEqpCpctUnitId !== undefined &&
                                           item2.emksEqpKndCd !== null && item2.emksEqpKndCd !== "" && item2.emksEqpKndCd !== undefined &&
                                           item2.emksPrtclKndCd !== null && item2.emksPrtclKndCd !== "" && item2.emksPrtclKndCd !== undefined &&
                                           item2.emksRsrcClCd !== null && item2.emksRsrcClCd !== "" && item2.emksRsrcClCd !== undefined &&
                                           item2.flxRsrcCpctUnitId !== null && item2.flxRsrcCpctUnitId !== "" && item2.flxRsrcCpctUnitId !== undefined &&
                                           item2.eqpCpct !== null && item2.eqpCpct !== "" && item2.eqpCpct !== undefined &&
                                           item2.flxRsrcInstmPsblYn !== null && item2.flxRsrcInstmPsblYn !== "" && item2.flxRsrcInstmPsblYn !== undefined &&
                                           item2.ipAddr !== null && item2.ipAddr !== "" && item2.ipAddr !== undefined &&
                                           item2.eqpPortNo !== null && item2.eqpPortNo !== "" && item2.eqpPortNo !== undefined &&
                                           item2.msinNm !== null && item2.msinNm !== "" && item2.msinNm !== undefined &&
                                           item2.msinTpNm !== null && item2.msinTpNm !== "" && item2.msinTpNm !== undefined &&
                                           item2.flxRsrcCpct !== null && item2.flxRsrcCpct !== "" && item2.flxRsrcCpct !== undefined &&
                                           item2.flxRsrcNm !== null && item2.flxRsrcNm !== "" && item2.flxRsrcNm !== undefined;
                                     })
                               });
                           const checkGridData =
                               formSignList.every((item:any, index:number)=>{
                                 return gridRefs.current[index].current.getInstance().getData().length == 0;
                               })
                           if (check1 && hasNullCsthsNm && confirm) {
                             saveYN("N")
                           }  else if (!confirm) {
                             if(check1 && hasNullCsthsNm && checkGridData) {
                               saveYN("N")
                             }else if(!checkGridData){
                               toast.error("계측,제어 가능여부를 동의해주세요")
                             } else {
                               toast.error("필수값을 입력해 주세요!")
                             }

                           } else {
                             toast.error("필수값을 입력해 주세요!")
                           }
                         }}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}