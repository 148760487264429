import { useEffect } from "react";

export default function ActvPrintModal(props: any) {

  useEffect(() => {
    var ozrName = '<%= ozrName %>';
    var pdfName = '<%= pdfName %>';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
          function SetOZParamters_OZViewer() {
            let oz;
            oz = document.getElementById("OZViewer");
            oz.sendToActionScript("connection.servlet", "${process.env.REACT_APP_URL_REPORT}/server");
            oz.sendToActionScript("connection.reportname", "/cp_actv.ozr");
            oz.sendToActionScript("connection.pcount", "1");
            oz.sendToActionScript("connection.args1", 'data=` + JSON.stringify(props.data) + `');

            oz.sendToActionScript("connection.displayname", "Sample Report");
            oz.sendToActionScript("connection.reportname","/SampleReports/Employee/" + ozrName);

            oz.sendToActionScript("export.format","pdf");
            oz.sendToActionScript("pdf.filename", pdfName + "_" + now);
            oz.sendToActionScript("pdf.fontembedding","true");
            oz.sendToActionScript("pdf.fontembedding_subset","true");

            return true;
          }
            var opt = [];
            opt["print_exportfrom"] = "server";
            opt["save_exportfrom"] = {"pdf" : "server"};
          start_ozjs("OZViewer", "${process.env.REACT_APP_URL_REPORT}/ozhviewer/", opt);
        `;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (

    <div style={{ margin: "20px" }}>
      <div className="inp-flex">
        <div id="OZViewer" className="OZViewer" style={{ overflowY: "auto", height: "650px" }}></div>
      </div>
    </div>
  )
}