import ArcButton, { ButtonColor, ButtonIcon } from "components/arc/ArcButton";
import ArcGrid from "components/arc/ArcGrid";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "reducers/modalSlice";
import ActvInsertModal from "./ActvInsertModal";
import { toast } from "react-toastify";
import AppAlert from "components/common/AppAlert";
import { t } from "i18next";
import { DocuEvdnService } from "services/cp/DocuEvdnService";
import { ResponseResultCode } from "model/response-api";
import ArcDatePicker, { ArcDatePickerResult } from "components/arc/ArcDatePicker";
import { RootState } from "stores/store";
import ActvPrintModal from "./ActvPrintModal";
import { ActvScopService } from "services/cp/ActvScopService";

export default function ActvList() {
  const gridRef: any = useRef<typeof ArcGrid>();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment().format('YYYY0101'));
  const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
  const reloadGridFlag = useSelector((state: RootState) => state.modal.reloadGrid); // Subscribe to reloadGrid state

  const addMonthToDate = (month: number) => {
    if (!endDate) {
      setEndDate(moment.utc().local().format('YYYYMMDD'));
      const newDate = moment().subtract(month, 'months').toString();
      setStartDate(moment.utc(newDate).local().format('YYYYMMDD'));
    } else {
      const newDate = moment(endDate, 'YYYYMMDD').subtract(month, 'months').toString();
      setStartDate(moment.utc(newDate).local().format('YYYYMMDD'));
    }
  }

  useEffect(() => {
    if (reloadGridFlag) {
      gridRef.current.getInstance().reloadData();
    }
  }, [reloadGridFlag]);

  const handleClickPrintBtn = () => {
    const checkArray = gridRef.current.getInstance().getCheckedRows();
    const checkedNum = checkArray.length;

    if (checkedNum == 1) {
      let data = {
        aplyYmd: "",
        strtYmd: "",
        endYmd: ""
      }
      checkArray.map((i: any) => { data.aplyYmd = String(i.aplyYmd) })
      data.strtYmd = data.aplyYmd != "" && data.aplyYmd.length == 12 ? data.aplyYmd.slice(0, 6) + "00" : ""
      data.endYmd = data.aplyYmd != "" && data.aplyYmd.length == 12 ? data.aplyYmd.slice(6,) + "00" : ""
      getReportData(data)
    } else {
      toast.error("1개의 데이터를 선택해주시기 바랍니다.");
    }
  }

  async function getReportData(data: any) {
    const response = await ActvScopService.getInstance().getReportData(data);
    if (response?.resultCode == "Y" && response?.data) {
      moveModal(response.data);
    } else {
      toast.error("시스템 오류가 발생하였습니다.");
    }
  }

  function moveModal(data: any) {
    dispatch(
      openModal({
        path: "/modal/actv/print/modal",
        target: ActvPrintModal,
        modalProps: {
          data: data,
          onClose: closeFormDialog,
        },
      })
    );
  }

  const closeFormDialog = () => {
    dispatch(closeModal());
  };

  function insertBtn() {
    dispatch(
      openModal({
        path: "/cp/reduce/docu/actvList/modal/insert",
        target: ActvInsertModal,
      })
    )
  }

  const handleClickDeleteBtn = () => {
    const checkedNum = gridRef.current.getInstance().getCheckedRows().length;
    if (checkedNum > 0) {
      dispatch(
        openModal({
          path: "cp/confirm/apply/delete/confirm",
          target: AppAlert,
          wrapStyle: { width: "20rem" },
          confirmProps: {
            type: "delete",
            title: `선택된 ${checkedNum}개의 활동자료를 ${t("confirm.label.message.delete")}`,
            onConfirm: handleDel,
          },
        })
      );
    } else {
      toast.warning(t('common.message.warn.noDeleteTarget'));
    }
  };

  async function handleDel() {
    let chkArray = gridRef.current.getInstance().getCheckedRows();
    if (chkArray.length > 0) {
      gridRef.current.getInstance().removeCheckedRows();
      try {
        const response = await DocuEvdnService.getInstance().deleteDocuActvs(chkArray);
        if (response) {
          if (ResponseResultCode.Y === response.resultCode) {
            toast.success(t("common.message.success.delete", { title: `${chkArray.length}개의 활동자료가` }));
            gridRef.current?.getInstance().reloadData()

          } else {
            toast.error(t("common.message.error.delete", { title: '활동자료' }));
          }
        } else {
          toast.error(t('common.message.error.duringProcessing'));
        }
      } catch (error) {
        toast.error(t("common.message.error.system"));
      }
    }
  }

  const handleChangeDate = (result: ArcDatePickerResult) => {
    setStartDate(result.fromDateString ?? "");
    setEndDate(result.toDateString ?? "");
  };

  function formatAplyYmd(value: any): string {
    const yearStart = value.substring(0, 4);
    const monthStart = value.substring(4, 6);
    const yearEnd = value.substring(6, 10);
    const monthEnd = value.substring(10, 12);

    return `${yearStart}-${monthStart} ~ ${yearEnd}-${monthEnd}`;
  }

  const gridColumns: any[] = [
    {
      header: "docId",
      name: "docId",
      hidden: true,
    },
    {
      header: "적용기간",
      name: "aplyYmd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return formatAplyYmd(e.value);
        }
        return "";
      },
    },
    {
      header: "총 배출량(tCO2eq)",
      name: "totalEmsnQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          const [integerPart, decimalPart] = e.value.toString().split(".");
          const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          const formattedDecimalPart = decimalPart ? decimalPart.substring(0, 2) : "";
          return formattedDecimalPart ? `${formattedIntegerPart}.${formattedDecimalPart}` : formattedIntegerPart;
        }
        return "";
      },
    },
    {
      header: "Scope1",
      name: "totalScop1EmsnQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          const [integerPart, decimalPart] = e.value.toString().split(".");
          const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          const formattedDecimalPart = decimalPart ? decimalPart.substring(0, 2) : "";
          return formattedDecimalPart ? `${formattedIntegerPart}.${formattedDecimalPart}` : formattedIntegerPart;
        }
        return "";
      },
    },
    {
      header: "Scope2",
      name: "totalScop2EmsnQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          const [integerPart, decimalPart] = e.value.toString().split(".");
          const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          const formattedDecimalPart = decimalPart ? decimalPart.substring(0, 2) : "";
          return formattedDecimalPart ? `${formattedIntegerPart}.${formattedDecimalPart}` : formattedIntegerPart;
        }
        return "";
      },
    },
    {
      header: "Scope3",
      name: "totalScop3EmsnQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          const [integerPart, decimalPart] = e.value.toString().split(".");
          const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          const formattedDecimalPart = decimalPart ? decimalPart.substring(0, 2) : "";
          return formattedDecimalPart ? `${formattedIntegerPart}.${formattedDecimalPart}` : formattedIntegerPart;
        }
        return "";
      },
    }
  ];

  return (
    <>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <h3 className="titT1">내역 조회</h3>
            <div className="srh-filter2 mgb20">
              <ul>
                <li>
                  <span className="titT3 mgb0">조회기간</span>
                </li>
                <li>
                  <div className="calendar-picker">
                    <ArcDatePicker fromDate={startDate}
                      toDate={endDate}
                      selectionMode={"range"}
                      onChange={handleChangeDate}
                      viewType={"month"}
                      id={""}
                      name={""} />
                  </div>
                  <div className="dateBtn">
                    <ArcButton type={"text"} text={"1개월"} onClick={() => addMonthToDate(1)} />
                    <ArcButton type={"text"} text={"3개월"} onClick={() => addMonthToDate(3)} />
                    <ArcButton type={"text"} text={"6개월"} onClick={() => addMonthToDate(6)} />
                    <ArcButton type={"text"} text={"1년"} onClick={() => addMonthToDate(12)} />
                  </div>
                </li>
              </ul>
            </div>
            <div id="gridTbl1">
              <ArcGrid
                gridRef={gridRef}
                datasource={{
                  readData: {
                    url: "/cp/docuActv/list", method: "GET", initParams: {
                      usePrdStrtYmd: startDate,
                      usePrdEndYmd: endDate
                    }
                  }
                }}
                columns={gridColumns}
                rowHeaders={["checkbox"]}
                useGridInfoHeader={true}
                usePagination={true}
                onCheckRow={() => { }} />
            </div>

            <div className="btns ar">
              <ArcButton type={"custom"} hasIcon={true} onClick={handleClickPrintBtn} text={"출력"} color={ButtonColor.btn1} icon={ButtonIcon.print} />
              <ArcButton type={"delete"} hasIcon={true} onClick={handleClickDeleteBtn} />
              <ArcButton type={"regist"} hasIcon={true} onClick={insertBtn} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

