import Api from "services/common/Api";
import { FileInfoModel } from "model/common/fileInfoModel";
import { DocuEvdn } from "model/cp/docuEvdn";
import { ApiUrlUtil } from "utils/apiUrlUtil";
import { DocuActv } from "model/cp/DocuActv";

export class DocuEvdnService extends Api {
  private static service: DocuEvdnService;
  private static GET_URL: string =
    process.env["REACT_APP_URL_CP"] + "/docuEvdn/detail";
  private static DELETE_MANY_URL: string =
    process.env["REACT_APP_URL_CP"] + "/docuEvdn/deleteList";
  private static DELETE_URL: string =
    process.env["REACT_APP_URL_CP"] + "/docuEvdn/deleteRow";
  private static POST_URL: string =
    process.env["REACT_APP_URL_CP"] + "/docuEvdn/save";
  private static FILE_URL: string =
    process.env["REACT_APP_URL_CP"] + "/files/file";

  private static DELETE_MANY_ACTV_URL: string =
    process.env["REACT_APP_URL_CP"] + "/docuActv/deleteList";
  private static POST_ACTV_URL: string =
    process.env["REACT_APP_URL_CP"] + "/docuActv/save";

  public static getInstance(): DocuEvdnService {
    if (!DocuEvdnService.service) {
      DocuEvdnService.service = new DocuEvdnService();
    }
    return DocuEvdnService.service;
  }

  // 방법론 상세
  public async getDocuEvdnDetail(docId: string) {
    return await super.get<DocuEvdn>(DocuEvdnService.GET_URL, { docId: docId });
  }

  // 방법론 리스트 삭제
  public async deleteDocuEvdns(docuEvdns: DocuEvdn[]) {
    return await super.post<DocuEvdn[]>(DocuEvdnService.DELETE_MANY_URL, {
      deletedRows: docuEvdns,
    });
  }

  // 방법론 단일 삭제
  public async deleteDocuEvdn(docuEvdn: DocuEvdn) {
    var docId = docuEvdn.docId;
    var mainFileId = null;
    if (
      docuEvdn.file?.mainFileId != undefined ||
      docuEvdn.file?.mainFileId != null
    ) {
      mainFileId = docuEvdn.file?.mainFileId;
    }

    return await super.delete<DocuEvdn>(DocuEvdnService.DELETE_URL, {
      docId: docId,
      mainFileId: mainFileId,
    });
  }

  // 방법론 저장
  public async saveDocuEvdn(form: FormData) {
    return await super.post<DocuEvdn>(DocuEvdnService.POST_URL, form);
  }

  // 파일 다운로드
  public async downloadFile(file: FileInfoModel) {
    const param = {
      filePath: file.pthNm,
      fileName: file.fileNm,
      originalFileName: file.realNm,
    };

    await super.download(DocuEvdnService.FILE_URL, param);
  }

  public async deleteDocuActvs(docuActv: DocuActv[]) {
    return await super.post<DocuActv[]>(DocuEvdnService.DELETE_MANY_ACTV_URL, {
      deletedRows: docuActv,
    });
  }

  public async saveDocuActv(form: DocuActv) {
    return await super.post<DocuActv>(DocuEvdnService.POST_ACTV_URL, form);
  }
}
