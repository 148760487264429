import React, { useEffect, useState } from "react";
import Scope1 from "./Scope1";
import Scope2 from "./Scope2";
import Scope3 from "./Scope3";
import ArcInput from "components/arc/ArcInput";
import ArcDatePicker, { ArcDatePickerResult } from "components/arc/ArcDatePicker";
import ArcButton, { ButtonColor, ButtonIcon, ButtonType } from "components/arc/ArcButton";
import { toast } from "react-toastify";
import { ActvScopService } from "services/cp/ActvScopService";
import { ResponseResultCode } from "model/response-api";
import { useNavigate, useParams } from "react-router-dom";
import { openModal } from "reducers/modalSlice";
import AppAlert from "components/common/AppAlert";
import { useAppDispatch } from "stores/hook";
import { useTranslation } from "react-i18next";
import i18n from "config/translation/i18n";
import { JsxElementConstant } from "constants/jsxElementConstant";

export interface BoardScopeProps {
  type?: BoardScopTypes;
  fuflYy?: number;
  scopUse?: string;
  save?: boolean;
  data?: any;
  onData?: any;
}

export const boardScopTypes = {
  scop1: "scope1",
  scop2: "scope2",
  scop3: "scope3",
} as const;

export const scop1Data = {
  scop1Fuel: { row: [] as any[], data: [] as any[] },
  scop1Waste: { row: [] as any[], data: [] as any[] },
  scop1Emiss: { row: [] as any[], data: [] as any[] },
} as const;

export const scop2Data = {
  scop2Elec: { row: [] as any[], data: [] as any[] },
  scop2Water: { row: [] as any[], data: [] as any[] },
} as const;

export const scop3Data = { row: [] as any[], data: [] as any[] } as const;

export type BoardScopTypes = (typeof boardScopTypes)[keyof typeof boardScopTypes];

export default function ActiveDataDetail() {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentYear = new Date().getFullYear();
  const today: Date = new Date();
  const [totSalesAmt, setTotSalesAmt] = useState("");
  const [mngId, setMngId] = useState("");
  const { type = boardScopTypes.scop1 } = useParams();
  const { scopYear } = useParams<{ scopYear: string }>();
  const maxDateForFromDate = new Date(currentYear, 11, 31);
  const [scopUse, setScopUse] = useState<any>();
  const navigate = useNavigate();

  const [scop1, setScop1] = useState(scop1Data);
  const [scop2, setScop2] = useState(scop2Data);
  const [scop3, setScop3] = useState(scop3Data);


  useEffect(() => {
    getSalesAmt();
  }, [scopYear]);

  const onChangeTotSalesAmt = (e: any) => {
    const valueWithoutCommas = removeCommas(e);

    setTotSalesAmt(valueWithoutCommas);

  };

  const onChangeScopYear = (e: ArcDatePickerResult) => {
    const year = Number(e.fromDateString ? e.fromDateString.substring(0, 4) : today.getFullYear());
    setScop1(scop1Data);
    setScop2(scop2Data);
    setScop3(scop3Data);
    getSalesAmt();

    navigate(`/cp/inventory/activity/${type}/${year}`);
  }

  function sortArray(array: any[]) {
    array.sort((a, b) => a.rowKey - b.rowKey);

    array.forEach((item, index) => {
      item.sortKey = index;
    });
  }

  const handleClick = (newType: string) => {

    if (type !== newType) {
      if (newType === "scope1") {

        let array1 = scop1.scop1Fuel.data;
        let array2 = scop1.scop1Waste.data;
        let array3 = scop1.scop1Emiss.data;

        sortArray(array1)
        sortArray(array2)
        sortArray(array3)
      }

      if (newType === "scope2") {

        let array1 = scop2.scop2Elec.data;
        let array2 = scop2.scop2Water.data;

        sortArray(array1)
        sortArray(array2)
      }

      if (newType === "scope3") {

        let array1 = scop3.data;

        sortArray(array1)
      }

      navigate(`/cp/inventory/activity/${newType}/${scopYear}`);
    }
  };

  const handleDataFromChild1 = (data: any) => {
    setScop1(data);
  };

  const handleDataFromChild2 = (data: any) => {
    setScop2(data);
  };

  const handleDataFromChild3 = (data: any) => {
    setScop3(data);
  };

  const getTabComponent = () => {
    if (type === boardScopTypes.scop1) {
      return <Scope1 fuflYy={Number(scopYear)} scopUse={scopUse?.scopN1UseYn} data={scop1} onData={handleDataFromChild1} />;
    } else if (type === boardScopTypes.scop2) {
      return <Scope2 fuflYy={Number(scopYear)} scopUse={scopUse?.scopN2UseYn} data={scop2} onData={handleDataFromChild2} />;
    } else {
      return <Scope3 fuflYy={Number(scopYear)} scopUse={scopUse?.scopN3UseYn} data={scop3} onData={handleDataFromChild3} />;
    }
  };

  // 사용자 년도 별 매출액 관련
  // 매출액 조회
  function getSalesAmt() {
    ActvScopService.getInstance()
      .getSalesAmt(String(scopYear))
      .then((response) => {
        if (response.data.data.length > 0) {
          setTotSalesAmt(response.data.data[0].totSalesAmt);
          setMngId(response.data.data[0].mngId);
        } else {
          setTotSalesAmt("");
          setMngId("");
        }
      })
      .catch(() => toast.error(t("common.message.error.system")));
  }

  const handleClickSave = () => {
    if (Number(totSalesAmt) >= 0) {
      if (Number(scopYear) > 0) {
        dispatch(openModal({
          path: 'cp/inventory/activity/save/confirm',
          target: AppAlert,
          wrapStyle: { width: "20rem" },
          confirmProps: {
            type: 'confirm',
            title: t('confirm.label.message.save'),
            onConfirm: save,
          }
        }));
      } else {
        toast.error('연도를 선택하세요.');
      }
    } else {
      toast.error('매출액을 숫자로 선택하세요.');
    }
  };

  const checkValid = (scop: string, validR: any, validUnique: any) => {
    const uniqueSet = new Set();

    switch (scop) {
      case "scop1Ful":
        scop1.scop1Fuel.data.forEach((item: any) => {
          if (item.emsnFcltNm == null || item.emsnFcltNm === undefined || item.fuelCd == null) {
            validR = false
          }
          if (item.emsnFcltNm != null && item.fuelCd != null) {
            const key = `${item.emsnFcltNm}-${item.fuelCd.comCd}`;
            if (uniqueSet.has(key)) {
              validUnique = false
            } else {
              uniqueSet.add(key);
            }
          }
        });
        break;
      case "scop1Was":
        scop1.scop1Waste.data.forEach((item: any) => {
          if (item.emsnFcltNm == null || item.emsnFcltNm === undefined || item.wstLwpoKndCd == null) {
            validR = false
          }
          if (item.emsnFcltNm != null && item.wstLwpoKndCd != null) {
            const key = `${item.emsnFcltNm}-${item.wstLwpoKndCd.comCd}`;
            if (uniqueSet.has(key)) {
              validUnique = false
            } else {
              uniqueSet.add(key);
            }
          }
        });
        break;
      case "scop1Emis":
        scop1.scop1Emiss.data.forEach((item: any) => {
          if (item.emsnLwpoKndCd == null) {
            validR = false
          }
          if (item.emsnLwpoKndCd != null) {
            const key = `${item.emsnLwpoKndCd.comCd}`;
            if (uniqueSet.has(key)) {
              validUnique = false
            } else {
              uniqueSet.add(key);
            }
          }
        });
        break;
      case "scop2Elec":
        scop2.scop2Elec.data.forEach((item: any) => {
          if (item.emsnFcltNm == null || item.emsnFcltNm === undefined || item.fuelCd == null) {
            validR = false
          }
          if (item.emsnFcltNm != null && item.fuelCd != null) {
            const key = `${item.emsnFcltNm}-${item.fuelCd.comCd}`;
            if (uniqueSet.has(key)) {
              validUnique = false
            } else {
              uniqueSet.add(key);
            }
          }
        });
        break;
      case "scop2Warter":
        scop2.scop2Water.data.forEach((item: any) => {
          if (item.emsnFcltNm == null || item.emsnFcltNm === undefined || item.fuelCd == null) {
            validR = false
          }
          if (item.emsnFcltNm != null && item.fuelCd != null) {
            const key = `${item.emsnFcltNm}-${item.fuelCd.comCd}`;
            if (uniqueSet.has(key)) {
              validUnique = false
            } else {
              uniqueSet.add(key);
            }
          }
        });
        break;
      case "scop3":
        scop3.data.forEach((item: any) => {
          if (item.n3Name == null || item.n3Name === undefined || item.kndCd == null || item.fuelName == null || item.fuelName == undefined) {
            validR = false
          }
          if (item.n3Name != null && item.kndCd != null && item.fuelName != null) {
            const key = `${item.n3Name}-${item.kndCd.comCd} - ${item.fuelName}`;
            if (uniqueSet.has(key)) {
              validUnique = false
            } else {
              uniqueSet.add(key);
            }
          }
        });
        break;
      default:
        return { validR: true, validUnique: true };
    }

    return { validR, validUnique }
  }

  function messageVali(validR: boolean, validUnique: boolean) {
    if (!validR || !validUnique) {
      if (!validR) {
        toast.error(i18n.t("common.message.valid.input.required"));
      } else if (!validUnique) {
        toast.error(i18n.t("common.message.valid.input.unique"));
      }
      return false;
    }
    return true;
  }

  const save = async () => {
    let validR = true;
    let validUnique = true;
    let result = { validR: true, validUnique: true }
    let scop = ["scop1Ful", "scop1Was", "scop1Emis", "scop2Elec", 'scop2Warter', "scop3"]

    for (let i = 0; i < scop.length; i++) {
      let scopCheck = scop[i];
      result = checkValid(scopCheck, validR, validUnique);
      validR = result.validR;
      validUnique = result.validUnique;

      if (!messageVali(validR, validUnique)) {
        return
      }
    }

    const data = {
      mngId: mngId,
      fuflYy: scopYear,
      totSalesAmt: totSalesAmt,
    };

    const response = await ActvScopService.getInstance().saveSalesAmt(data);

    if (response && response.data) {
      if (ResponseResultCode.Y === response.resultCode) {
        saveScop();
        if (typeof response.data.mngId === "string") {
          setMngId(response.data.mngId);
        }
        toast.success(t("common.message.success.save", { title: "" }));
      } else {
        toast.error(t('common.message.error.system'));
      }
    }
  }

  useEffect(() => {
    getCheckScope();
  }, []);

  function getCheckScope() {
    ActvScopService.getInstance()
      .getCheckScope()
      .then((response: any) => {
        if (response?.data) {
          setScopUse(response.data);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.system"));
      });
  }

  function clickBackBtn() {
    navigate(`/cp/inventory/activity`);
  }

  const removeCommas = (number: any) => {
    return number.replace(/,/g, '');
  };

  const formatNumberWithCommas = (number: any) => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
  };

  const saveScop = () => {

    const dataScop1Ful = scop1.scop1Fuel.data.filter((obj: any) => scop1.scop1Fuel.row.includes(obj.rowKey));
    const dataScop1Was = scop1.scop1Waste.data.filter((obj: any) => scop1.scop1Waste.row.includes(obj.rowKey));
    const dataScop1Emi = scop1.scop1Emiss.data.filter((obj: any) => scop1.scop1Emiss.row.includes(obj.rowKey));

    const dataScop2Elec = scop2.scop2Elec.data.filter((obj: any) => scop2.scop2Elec.row.includes(obj.rowKey));
    const dataScop2Water = scop2.scop2Water.data.filter((obj: any) => scop2.scop2Water.row.includes(obj.rowKey));

    const dataScop3 = scop3.data.filter((obj: any) => scop3.row.includes(obj.rowKey));

    const dataScop1 = dataScop1Ful.concat(dataScop1Was, dataScop1Emi);

    const dataScop2 = dataScop2Elec.concat(dataScop2Water);

    let nullIDsScop1 = dataScop1.filter((item: any) => item.mngId === null);
    let nonNullIDsScop1 = dataScop1.filter((item: any) => item.mngId !== null);

    let nullIDsScop2 = dataScop2.filter((item: any) => item.mngId === null);
    let nonNullIDsScop2 = dataScop2.filter((item: any) => item.mngId !== null);

    let nullIDsScop3 = dataScop3.filter((item: any) => item.mngId === null);
    let nonNullIDsScop3 = dataScop3.filter((item: any) => item.mngId !== null);

    handleInsertScop1(nullIDsScop1)
    handleUpdateScop1(nonNullIDsScop1)

    handleInsertScop2(nullIDsScop2)
    handleUpdateScop2(nonNullIDsScop2)

    handleInsertScop3(nullIDsScop3)
    handleUpdateScop3(nonNullIDsScop3)

  }

  const handleInsertScop1 = async (data: any) => {
    if (data.length > 0) {
      const requestBody = {
        createdRows: data
      };
      try {
        await ActvScopService.getInstance().insertScop1(requestBody);
        setScop1(scop1Data);
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  };

  const handleUpdateScop1 = async (data: any) => {
    if (data.length > 0) {
      const requestBody = {
        updatedRows: data
      };
      try {
        await ActvScopService.getInstance().updateScop1(requestBody);
        setScop1(scop1Data);
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  };

  const handleInsertScop2 = async (data: any) => {
    if (data.length > 0) {
      const requestBody = {
        createdRows: data
      };
      try {
        await ActvScopService.getInstance().insertScop2(requestBody);
        setScop2(scop2Data);
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  };

  const handleUpdateScop2 = async (data: any) => {
    if (data.length > 0) {
      const requestBody = {
        updatedRows: data
      };
      try {
        await ActvScopService.getInstance().updateScop2(requestBody);
        setScop2(scop2Data);
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  };

  const handleInsertScop3 = async (data: any) => {
    if (data.length > 0) {
      const requestBody = {
        createdRows: data
      };
      try {
        await ActvScopService.getInstance().insertScop3(requestBody);
        setScop3(scop3Data);
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  };

  const handleUpdateScop3 = async (data: any) => {
    if (data.length > 0) {
      const requestBody = {
        updatedRows: data
      };
      try {
        await ActvScopService.getInstance().updateScop3(requestBody);
        setScop3(scop3Data);
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  };

  return (
    <div className="container">
      <section className="section active">
        <div className="srh-filter">
          <h3 className="titT1 mgb0">활동자료</h3>
          <ul>
            <li className="mgl20">
              <span className="srh-tit fs16">연도</span>
              <ArcDatePicker
                onChange={onChangeScopYear}
                id={""}
                name={""}
                fromDate={String(String(Number(scopYear) > 0 ? scopYear : ""))}
                viewType={"year"}
                maxDate={maxDateForFromDate}
              />
            </li>
            <li className="mgl20">
              <div className="srh-cont">
                <ArcInput
                  label={'매출액'}
                  displayTitle={true}
                  value={formatNumberWithCommas(totSalesAmt)}
                  type={"w100p"}
                  inputType={"text"}
                  maxLength={10}
                  minValue={0}
                  disabled={false}
                  readonly={false}
                  maxValue={9999999999}
                  placeholder={"매출액을 입력해주세요"}
                  prefix={""}
                  id={"input-1"}
                  name={"input-1"}
                  className="InpSel-200 ar"
                  onChange={(e) => onChangeTotSalesAmt(e)
                  }
                />
                <span className="input-util">억원</span>
              </div>
            </li>
          </ul>
          <ArcButton
            type={ButtonType.custom}
            text={"목록"}
            color={ButtonColor.btn1}
            onClick={clickBackBtn}
          />
          <ArcButton
            type={ButtonType.regist}
            text={t("common.button.save")}
            hasIcon={true}
            icon={ButtonIcon.save}
            onClick={handleClickSave}
          />
        </div>

        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <div className="tab-st-box1">
                <ul className="tab-st1">
                  <li className={type === boardScopTypes.scop1 ? "on" : ""}>
                    <a
                      href={JsxElementConstant.hrefIsNull}
                      onClick={() => handleClick(boardScopTypes.scop1)}>
                      <span>Scope1</span>
                    </a>
                  </li>

                  <li className={type === boardScopTypes.scop2 ? "on" : ""}>
                    <a
                      href={JsxElementConstant.hrefIsNull}
                      onClick={() => handleClick(boardScopTypes.scop2)}>
                      <span>Scope2</span>
                    </a>
                  </li>

                  <li className={type === boardScopTypes.scop3 ? "on" : ""}>
                    <a
                      href={JsxElementConstant.hrefIsNull}
                      onClick={() => handleClick(boardScopTypes.scop3)}>
                      <span>Scope3</span>
                    </a>
                  </li>
                </ul>
              </div>
              {getTabComponent()}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
