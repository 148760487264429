import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {DbAdminService} from "services/db/DbAdminService";
import {ResponseResultCode} from "../../../model/response-api";

export default function Re100ParticipationStatusChart1() {
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [re100Status, setRe100Status] = useState<Highcharts.Options | null>(null);

  const callback = useCallback((HighchartsChart: Highcharts.Chart | null) => {
    setChart(HighchartsChart);
  }, []);

  useEffect(() => {
    getStatusByScale();
  }, []);

  async function getStatusByScale() {
    const response = await DbAdminService.getInstance().getStatusByScale();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        const data = response.data;

        const smallBusinessData = Object.values(data.ECOM003 ?? {}).map((v: any) => v * 100);
        const mediumBusinessData = Object.values(data.ECOM002 ?? {}).map((v: any) => v * 100);
        const largeBusinessData = Object.values(data.ECOM001 ?? {}).map((v: any) => v * 100);

        const updatedOptions: Highcharts.Options = {
          ...options,
          series: [
            {
              type: "column",
              name: "대기업",
              data: largeBusinessData,
              color: "#15E4C6",
              groupPadding: 0,
              borderWidth: 0,
              pointWidth: 60,

            },
            {
              type: "column",
              name: "중견기업",
              data: mediumBusinessData,
              color: "#2CD6F7",
              groupPadding: 0,
              borderWidth: 0,
              pointWidth: 60,
            },
            {
              type: "column",
              name: "중소기업",
              data: smallBusinessData,
              color: "#3C92FF",
              groupPadding: 0,
              borderWidth: 0,
              pointWidth: 60,
            },
          ],
        };

        setRe100Status(updatedOptions);
      } else {
        setRe100Status(options);
      }
    } else {
      setRe100Status(options);
    }
  }

  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    exporting: {enabled: false},
    credits: {enabled: false},
    xAxis: {
      categories: ["녹색프리미엄", "REC구매", "제 3자 PPA", "직접 PPA", "자가발전"],
      labels: {
        style: {
          color: "#444",
          fontSize: "14px",
        },
      },
      lineColor: "#999999",
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#444",
          fontSize: "14px",
        },
      },
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      borderRadius: 20,
      symbolHeight: 10,
      padding: 10,
      backgroundColor: '#F1F2F7',
      itemStyle: {
        fontSize: '0.7rem',
        color: '#444444',
      },
    },
    plotOptions: {
      series: {
        stacking: "percent",
        dataLabels: {
          enabled: true,
          useHTML: true,
          style: {
            fontSize: "13px",
            color: "#fff",
            textOutline: "none",
          },
          formatter() {
            const percentage = isNaN(this.percentage) ? 0 : Math.round(this.percentage * 100) / 100;
            return (
                '<div style="text-align:center;"><span class="bar-graph-color-num" style="position:relative;display:inline-block;margin-top:5px;padding:2px 8px;font-weight:400;border-radius:15px;overflow:hidden;background:' +
                this.color +
                '"><span style="position:relative;display:block;z-index:1;color:#fff;font-size:.55rem;font-weight:400;">' +
                percentage + // NaN이면 0으로 표시
                '%</span><em style="position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.3);"></em></span></div>'
            );
          },
        },
      },
    },
    series: [
      {
        type: "column",
        name: "대기업",
        data: [0, 0, 0, 0, 0],
        color: "#15E4C6",
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 60,
      },
      {
        type: "column",
        name: "중견기업",
        data: [0, 0, 0, 0, 0],
        color: "#2CD6F7",
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 60,
      },
      {
        type: "column",
        name: "중소기업",
        data: [0, 0, 0, 0, 0],
        color: "#3C92FF",
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 60,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            chart: {
              spacingTop: 20,
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: '0.5rem',
                },
              },
            },
            yAxis: [
              {
                title: {
                  y: -10,
                  style: {
                    fontSize: '0.5rem',
                  },
                },
                labels: {
                  style: {
                    fontSize: '0.5rem',
                  },
                },
              },
            ],
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
              itemStyle: {
                fontSize: '0.6rem',
              },
            },
            tooltip: {
              style: {
                fontSize: '0.6rem',
              }
            },
            plotOptions: {
              line: {
                lineWidth: 1,
                marker: {
                  lineWidth: 1,
                  width: 2,
                  height: 2,
                  radius: 2
                },
              },
            },
          },
        },
      ],
    },

  };

  return (
      <>
        {re100Status && (
            <HighchartsReact
                highcharts={Highcharts}
                options={re100Status}
                callback={callback}
                ref={chartRef}
            />
        )}
      </>
  );
}