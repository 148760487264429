import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from "stores/hook";
import { EmarketMenuModel } from "reducers/userMenuSlice";
import { dbName } from "routers/dbRouter";
import { myPageName } from "routers/mpRouter";
import { isStringNullOrEmpty } from "utils/stringUtil";

export default function Breadcrumbs() {
  const location = useLocation();
  const userMenu: EmarketMenuModel[] = useAppSelector(state => state.userMenu.userMenu);
  const userInfo = useAppSelector(state => state.userInfo.userInfo);

  const [currentMenu, setCurrentMenu] = useState<string>();
  const [breadcrumbs, setBreadcrumbs] = useState<{id: string, name: string}[]>([]);
  const [homeName, setHomeName] = useState('홈');

  useEffect(() => {
    setHomeName('E-Market 플랫폼');
  }, [userInfo]);

  useEffect(() => {
    setBreadcrumbs([]);
    findAndSetBreadcrumbs();
  }, [location, userMenu]);

  useEffect(() => {
    document.title = currentMenu??'E-Market 플랫폼';
  }, [currentMenu]);

  function findAndSetBreadcrumbs() {
    if(location.pathname.startsWith('/db')) {
      dbName.forEach((item: {path: string, pageNm: string}) => {
        if(item.path === location.pathname) {
          if(item.pageNm) {
            breadcrumbs.push({ id: crypto.randomUUID(), name: item.pageNm });
          }
          setCurrentMenu(isStringNullOrEmpty(item.pageNm) ? undefined : item.pageNm);
        }
      });
    } else if(location.pathname.startsWith('/mypage')) {
      myPageName.forEach((item: {path: string, pageNm: string}) => {
        if (item.path === location.pathname) {
          breadcrumbs.push({ id: crypto.randomUUID(), name: item.pageNm });
          setCurrentMenu(isStringNullOrEmpty(item.pageNm) ? undefined : item.pageNm);
        }
      });
    } else {
      findMenuNameFromUserMenu(userMenu, location.pathname);
    }
  }

  function findMenuNameFromUserMenu(menus: EmarketMenuModel[], pathname: string) {
    const flatMenus = convertToFlatMenuArray(menus);

    flatMenus.forEach((menu: EmarketMenuModel) => {
      if(pathname === menu.url) {
        if(menu.upperMenuId) {
          findUpperMenuFromUserMenu(flatMenus, menu.upperMenuId);
        }

        setBreadcrumbs(prev => {
          prev.push({ id: menu.menuId, name: menu.menuName });
          return prev;
        });
        setCurrentMenu(isStringNullOrEmpty(menu.menuName) ? undefined : menu.menuName);
        return;
      }
    });
  }

  function findUpperMenuFromUserMenu(menus: EmarketMenuModel[], upperMenuId: string) {
    menus.forEach((menu: EmarketMenuModel) => {
      if(upperMenuId === menu.menuId) {
        setBreadcrumbs(prev => {
          prev.unshift({ id: menu.menuId, name: menu.menuName });
          return prev;
        });
        if(menu.upperMenuId) {
          findUpperMenuFromUserMenu(menus, menu.upperMenuId);
        }
        return;
      }
    });
  }

  function convertToFlatMenuArray(menus: EmarketMenuModel[]) {
    const flatMenuArray: EmarketMenuModel[] = [];
    for(let menu of menus) {
      flatMenuArray.push(...convertToList(menu));
    }
    return flatMenuArray;
  }

  function convertToList<T>(rootMenu: T) {
    const menuToCheck: T[] = [{...rootMenu, depth: 1}];
    const flatMenus: T[] = [];

    while(menuToCheck.length > 0) {
      const menu: any = {...menuToCheck.shift()};
      if(menu) {
        if(menu.childMenus && menu.childMenus.length > 0) {
          menu.childMenus.forEach((sub: T) => menuToCheck.push({...sub, depth:menu.depth === 1 ? 2 : 3}));
          delete menu.childMenus;
        }
        flatMenus.push(menu);
      }
    }
    return flatMenus;
  }

  return (
    <>
      <div className="location" id="location">
        <span>{homeName}</span>
        {
          breadcrumbs.map((e, index) => {
            if(breadcrumbs.length === index + 1) {
              return <strong key={e.id}>{e.name}</strong>
            } else {
              return <span key={e.id}>{e.name}</span>
            }
          })
        }
      </div>
    </>
  )
}
