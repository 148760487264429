

export class UserMemberModel {

    emksMbrsClCd?: string;

    mktgJoinAgreYn?: string
    emksRe100JoinClCd?: string;
    userId?: string;

    userPwdEncVal?: string;
    conFirmPw?: string;

    bizrRegNo?: string
    comRegNumFi?: string;
    comRegNumSe?: string;
    comRegNumTh?: string;

    bizrNm?: string;
    reprNm?: string;

    coRepNo?: string
    repNum1?: number;
    repNum2?: number;
    repNum3?: number;

    bizrAddr?: string;
    bizrDtlsAddr?: string;

    tchgrNm?: string;

    tchgrCplcNo?: string;
    conPerChar1?: number;
    conPerChar2?: number;
    conPerChar3?: number;

    tchgrEmailAddr?: string;

    emksEntClCd?: string;

    staIndClaCerId?: string;
    staIndClaCer1?: string;
    staIndClaCer2?: string;
    bzknds?: any[]; // 임시저장 데이터 불러올때 사용

    attBusRegCer?: any;
    userTmpSavYn?: string;
    opbizYmd?: string;

    deleteFile?: any[];
    custRegRqss?: any;

    // 사업자등록증 파일
    file?: any;
    fileResponses?: any;
    fileInfos?: any;
    repAtflId?: string;
    isExistFileDelete?: boolean;

    // 로고 파일
    logo?: any;
    logoPreview?: any;
    coLogoAtflId?: string;
    isExistLogoFileDelete?: boolean;

    // 인감 파일
    ofcsl?: any;
    ofcslPreview?: any;
    coOfcslAtflId?: string;
    isExistOfcslFileDelete?: boolean;

    custRegRqsRsrcs?: any;

    // 그 외
    eltrSgntCtt?: any;
    userCoNm?: any;
    userCoAddr?: any;
    userCoDtlsAddr?: any;
    bizrRegcAtflId?: any;
    bizrRegcAtflNm?: any;
    aprYn?: any;
    aprDt?: any;
    useYn?: any;
    userKepcoCustInfos?: any;

    // 사업자번호, 이메일 진위여부 확인 필요 여부. true인 경우 확인 완료.
    isModifyRegNo?: boolean;
    isModifyEmail?: boolean;

    // 사용자인 경우 이메일
    tchgrEmailAddr1?: any;
    tchgrEmailAddr2?: any;

    // 사용자 권한ID 배열
    authIds?: string[];

    custRegRqsStatus?: any;
}