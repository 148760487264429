import ArcGrid, {
  CustomCommonCodeRenderer,
  CustomSelectCommonCodeRenderer,
  GridColumn,
  GridComplexHeader,
  GridEditor,
  GridEditorInfo,
} from "components/arc/ArcGrid";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ComCdService } from "services/cp/ComCdService";
import moment from "moment";
import { parseNumberWithCommaDecimal } from "utils/stringUtil";
import ArcDatePicker from "components/arc/ArcDatePicker";
import { AuthConstant } from "constants/authConstant";
import { UserInfoUtil } from "utils/userInfoUtil";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { useAppSelector } from "stores/hook";

export default function WasteEmissionFactor() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const { t } = useTranslation();
  const [year, setYear] = useState<number>(moment().year());
  const [stsCd, setStsCd] = useState<any[]>([]);
  const [kndCd, setKndCd] = useState<any[]>([]);
  const [lwpoKndCd, setLwpoKndCd] = useState<any[]>([]);
  const [unitCd, setUnitCd] = useState<any[]>([]);

  // select로 보여질 단위 조회
  useEffect(() => {
    getStsCd();
    getKndCd();
    getLwpoKndCd();
    getUnitCd();
  }, []);

  function getStsCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_WST_STS_CL_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setStsCd(cdOptions);
        } else {
          setStsCd([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  function getKndCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_WST_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setKndCd(cdOptions);
        } else {
          setKndCd([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  function getLwpoKndCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_WST_LWPO_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setLwpoKndCd(cdOptions);
        } else {
          setLwpoKndCd([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  function getUnitCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_EMSRC_COEF_UNIT_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setUnitCd(cdOptions);
        } else {
          setUnitCd([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  function makeCommonCodeOptions(data: any[]) {
    return data.map((item: any) => ({
      label: item.comCdExpl,
      value: item.comCd,
      uppoComCd: item.uppoComCd,
    }));
  }
  // 그리드. 체크박스
  const gridRef: any = useRef<typeof ArcGrid>();

  // 그리드 헤더
  const gridHeader: GridComplexHeader = {
    height: 90,
    complexColumns: [
      {
        header: t("cp.inventory.reference.wasteEmission.header.info"),
        name: "info",
        childNames: ["stsName", "kndName", "lwpoKndName", "unitName"],
      },
      {
        header: t("cp.inventory.reference.wasteEmission.header.coef"),
        name: "coef",
        childNames: ["cbdxEmsnCoef", "methnEmsnCoef", "dnmxEmsnCoef"],
      },
      {
        header: t("cp.inventory.reference.wasteEmission.header.wstCoef"),
        name: "wstCoef",
        childNames: ["drySbstContnQnt", "drySbstCrbnRt", "fslCrbnRt", "multiply"],
      },
    ],
  };

  const gridNumberParse = (e: any) => {
    if (e.value || 0 === e.value) {
      return parseNumberWithCommaDecimal(e.value);
    }
    return "";
  };
  const gridColumns: GridColumn[] = [
    {
      header: "emsnCoefId",
      name: "emsnCoefId",
      hidden: true,
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.wstStsClCd"),
      name: "wstStsClCd",
      align: "center",
      width: 100,

      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.wstKndCd"),
      name: "wstKndCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.wstLwpoKndCd"),
      name: "wstLwpoKndCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.wstIncnrUnitCd"),
      name: "wstIncnrUnitCd",
      align: "center",
      width: 100,
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.cbdxEmsnCoef"),
      name: "cbdxEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.methnEmsnCoef"),
      name: "methnEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.dnmxEmsnCoef"),
      name: "dnmxEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.drySbstContnQnt"),
      name: "drySbstContnQnt",
      align: "right",
      width: 80,
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.drySbstCrbnRt"),
      name: "drySbstCrbnRt",
      align: "right",
      width: 80,
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.fslCrbnRt"),
      name: "fslCrbnRt",
      align: "right",
      width: 80,
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.wasteEmission.header.product"),
      name: "product",
      align: "right",
      width: 80,
      whiteSpace: "normal",
      formatter: function (e: any) {
        const { drySbstContnQnt, drySbstCrbnRt, fslCrbnRt } = e.row;
        if (drySbstContnQnt != null && drySbstContnQnt != undefined && drySbstCrbnRt != null && drySbstCrbnRt != undefined && fslCrbnRt != null && fslCrbnRt != undefined) {
          let value = drySbstContnQnt * drySbstCrbnRt * fslCrbnRt;
          return parseNumberWithCommaDecimal(Math.round(value * 1000) / 1000);
        } else {
          return "-"
        }
      },
    },
  ];

  const numberEditorEmission = (length: number, decimal: number): Partial<GridEditorInfo> => {

    return {
      options: {
        type: "number",
        validation: {
          dataType: "number",
          required: true,
          regExp:
            new RegExp(`^\\d{0,${length - decimal}}(\\.\\d{0,${decimal}})?$`),
          regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
            len: length,
            dec: decimal
          })
        }
      },
    }
  }

  //그리드 수정
  const gridEditor: GridEditor = {
    defaultRowDataSet: { aplyYy: year },
    editableColumns: [
      {
        columnName: "wstStsClCd",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: stsCd,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            },
          },
        },
      },
      {
        columnName: "wstKndCd",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: kndCd,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            },
          },
        },
      },
      {
        columnName: "wstLwpoKndCd",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: lwpoKndCd,
          validation: {
            required: true,
            validatorFn: (value: any, row: any, columnNm: any) => {
              if (value?.comCdExpl === "-") return true
              if (value === null || value === undefined) return false;
              if (value?.comCdExpl === undefined) return false;
              const gridInstance = gridRef.current?.getInstance();
              if (!gridInstance) return false;
              const data = gridInstance.getData();
              if (!Array.isArray(data) || data.length === 0) return false;
              return data.filter((e: any) => e?.wstLwpoKndCd?.comCd === value.comCd).length === 1;
            },
          },
        },
      },
      {
        columnName: "wstIncnrUnitCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitCd,
          onChange: handleChangeEditor,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            },
          },
        },
      },
      {
        columnName: "cbdxEmsnCoef",
        editorType: "text",
        ...numberEditorEmission(20, 9),
      },
      {
        columnName: "methnEmsnCoef",
        editorType: "text",
        ...numberEditorEmission(20, 9),
      },
      {
        columnName: "dnmxEmsnCoef",
        editorType: "text",
        ...numberEditorEmission(20, 9),
      },
      {
        columnName: "drySbstContnQnt",
        editorType: "text",
        ...numberEditorEmission(20, 9),
      },
      {
        columnName: "drySbstCrbnRt",
        editorType: "text",
        ...numberEditorEmission(20, 9),
      },
      {
        columnName: "fslCrbnRt",
        editorType: "text",
        ...numberEditorEmission(20, 9),
      },
    ],
  };

  function handleChangeEditor(e: any) {
  }

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <h3 className="titT1">폐기물 소각 배출계수</h3>
            <ArcDatePicker
              onChange={(data) => setYear((data.fromDate ?? new Date()).getFullYear())}
              viewType="year"
              fromDate={String(year)}
              showButtonBar={false}
              id="selectedYear"
              name="selectedYear" />
          </div>
          <div className="fs14">
            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ?
              <ArcGrid
                gridRef={gridRef}
                key={`${stsCd.length}-${kndCd.length}-${lwpoKndCd.length}-${unitCd.length}`}
                datasource={{
                  readData: {
                    url: "/cp/refIncnrEmsnCoef",
                    method: "GET",
                    initParams: { year: year },
                  },
                  createData: { url: "/cp/refIncnrEmsnCoef", method: "POST" },
                  updateData: { url: "/cp/refIncnrEmsnCoef", method: "PUT" },
                  deleteData: {
                    url: "/cp/refIncnrEmsnCoef",
                    method: "DELETE",
                    serializer(params) {
                      const ids: any = params.deletedRows?.map((row: any) => ({ emsnCoefId: row.emsnCoefId }));
                      return encodeURI(`deletedRows=${JSON.stringify(ids)}`);
                    },
                  },
                }}
                columns={gridColumns}
                rowHeaders={["checkbox"]}
                usePagination={false}
                useGridInfoHeader={true}
                complexHeader={gridHeader}
                editor={gridEditor}
              />
              :
              <ArcGrid
                gridRef={gridRef}
                datasource={{
                  readData: {
                    url: "/cp/refIncnrEmsnCoef",
                    method: "GET",
                    initParams: { year: year },
                  },
                }}
                columns={gridColumns}
                usePagination={false}
                useGridInfoHeader={true}
                complexHeader={gridHeader}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
