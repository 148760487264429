import ArcInput from "components/arc/ArcInput";
import { useContext, useState } from "react";
import {
  BusinessFormContext
} from "pages/cp/reductionProject/history/BusinessForm/BusinessFormContext";
import { DocuBizPlnd } from "model/cp/DocuBizPlnd";
import ArcTextArea from "components/arc/ArcTextArea";
import { useNavigate } from "react-router-dom";
import ArcButton, { ButtonType } from "components/arc/ArcButton";
import ArcRadioButton, { RadioButtonDataModel } from "components/arc/ArcRadioButton";
import ArcFileUploader, { dbFileInfo } from "components/arc/ArcFileUploader";
import { CrudConstant } from "constants/crudConstant";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ArcSelectbox, { SelectDataModel } from "components/arc/ArcSelectbox";

interface BusinessFormInfoProps {
  form: DocuBizPlnd;
  mhdlgOptions: SelectDataModel[];
  onNext: Function;
}
export default function BusinessFormInfo(props: BusinessFormInfoProps) {
  const context = useContext(BusinessFormContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bizFileRadio: RadioButtonDataModel[] = [
    { value: "Y", label: "신규 작성" },
    { value: "N", label: "기존 파일 업로드" }
  ];

  const [form, setForm] = useState<DocuBizPlnd | undefined>(context.data.form ?? props.form);
  const [bizFileUploadType, setBizFileUploadType] = useState<"Y" | "N">(initBizFileUploadType);
  const [localFile, setLocalFile] = useState<File | undefined>(initLocalFile);
  const [dbFile,] = useState<dbFileInfo | undefined>(initDBFile);

  function initBizFileUploadType() {
    const file = context.data.businessFile;
    if (file) {
      return "N";
    } else {
      if (form?.file?.files && form?.file?.files.length > 0) {
        return "N";
      } else {
        return "Y";
      }
    }
  }

  function initLocalFile() {
    const file = context.data.businessFile;
    if (file && file instanceof File) {
      return file;
    }
  }

  function initDBFile() {
    let files: any[] = form?.file?.files ?? [];
    const notDeletedFiles = files.filter(f => CrudConstant.D !== f.crud);
    if (notDeletedFiles.length > 0) {
      let file = notDeletedFiles[0];
      return { name: file.realNm, size: file.size, fileId: file.fileId } as dbFileInfo;
    }
    return undefined;
  }

  function handleChangeSelect(event: any) {
    const name = event.target.options[event.target.selectedIndex].innerHTML;
    setForm({ ...form, mhdlgId: event.target.value, mhdlgNm: name });
  }

  function handleChangeInput(name: string, value: any) {
    setForm({ ...form, [name]: value });
  }

  function handleChangeBizFileUploadType(event: any) {
    setBizFileUploadType(event.target.value);
  }

  function handleClickAddFile(files: File[]) {
    if (files && files.length > 0) {
      setLocalFile(files[0]);
    }
  }

  function handleDBFile(files: any[]) {
    const index = files.findIndex(f => f.fileId === dbFile?.fileId);
    if (index >= 0) {
      let formFiles = form?.file?.files?.map(file => {
        if (file.fileId === dbFile?.fileId) {
          return { ...file, crud: CrudConstant.D };
        }
        return { ...file };
      });

      setForm({
        ...form,
        file: {
          ...form?.file,
          files: formFiles,
        }
      });
    }
  }

  function handleClickNext() {
    if (checkFormData()) {
      let businessFile;
      if (form?.docId) {
        if (form?.file?.files && form?.file?.files.length > 0) {
          businessFile = form?.file.files[0];
        }
        if (localFile) {
          businessFile = localFile;
        }
      } else {
        businessFile = localFile ?? undefined;
      }

      context.setData({
        form: form,
        businessFile: businessFile,
      });
      props.onNext();
    } else {
      toast.error(t('common.message.error.checkRequired'));
    }
  }

  function checkFormData() {
    let check: boolean = true;
    if (form === null || form === undefined) {
      return false;
    } else {
      if (!form.rlvnDataNm || form.rlvnDataNm === "") {
        check = false;
      }
      if (!form.mhdlgId || form.mhdlgId === "") {
        check = false;
      }
      if (bizFileUploadType === undefined) {
        check = false;
      }
      if (bizFileUploadType === "N" && !(localFile || dbFile)) {
        check = false;
      }
      return check;
    }
  }

  return (
    <div className="container">
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">사업계획서 등록</h3>
              <div className="tbl-st2 mgt20">
                <table>
                  <caption>사업계획서</caption>
                  <colgroup>
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "auto" }} />
                  </colgroup>
                  <tbody className="al">
                    <tr>
                      <td className="tbl-bg-g tbl-L-T2">
                        <label htmlFor={"title"} className="no-est bu-est" title={"*필수입력 사업계획서명"}>사업계획서명</label>
                      </td>
                      <td className="tbl-L-T2">
                        <span>
                          <ArcInput
                            inputType={"text"}
                            isRequired={true}
                            value={form?.rlvnDataNm ?? ""}
                            id={"title"}
                            title={"사업계획서명 입력"}
                            onChange={(text) => handleChangeInput("rlvnDataNm", text)}
                            placeholder={"제목을 입력하세요."}
                          />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="tbl-bg-g">
                        <label htmlFor={"mhdlgId"} className="no-est bu-est" title={"*필수입력 적용 방법론"}>적용 방법론</label>
                      </td>
                      <td>
                        <ArcSelectbox name="mhdlgId"
                          id={"mhdlgId"}
                          title={"적용 방법론 선택"}
                          useDefaultOption={true}
                          selectedValue={form?.mhdlgId}
                          onChange={handleChangeSelect}
                          options={props.mhdlgOptions} />
                      </td>
                    </tr>
                    <tr>
                      <td className="tbl-bg-g">
                        <label htmlFor={""} className="no-est bu-est" title={"*필수입력 사업계획서"}>사업계획서</label>
                      </td>
                      <td>
                        <div>
                          <ArcRadioButton dataSource={bizFileRadio}
                            selectedValue={bizFileUploadType}
                            nameGroup="selectBizFileUploadType"
                            onChange={handleChangeBizFileUploadType} />
                          {"N" === bizFileUploadType && (
                            <>
                              <hr className="line3 mgb10 mgt5" />
                              <ArcFileUploader
                                maxFileNum={1}
                                maxFileSizeMb={90}
                                dbFileList={dbFile ? [dbFile] : []}
                                localFileList={(localFile ? [localFile] : [])}
                                onUpload={handleClickAddFile}
                                dbUpload={handleDBFile} />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="tbl-bg-g">
                        <label htmlFor={"explCtt"} title={"설명"}>설명</label>
                      </td>
                      <td>
                        <ArcTextArea value={form?.explCtt || ""} rows={3} cols={40} type="w100p"
                          isRequired={true}
                          validateMessage={"Required"} placeholder={"설명을 입력하세요."}
                          prefix={""} maxLength={100}
                          id="explCtt" name="explCtt"
                          onChange={(e, _) => handleChangeInput('explCtt', e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="btns ar">
                <ArcButton type={ButtonType.cancel} onClick={() => navigate(-1)} />
                <ArcButton type={ButtonType.next} hasIcon onClick={handleClickNext} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}