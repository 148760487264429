import React, { useState } from "react";

interface ReduceGoalContextProps {
  emsn: number;
  years: any[];
  goalType: number;
  midYear: number;
  midPercent: number;
  goalYear: number;
  goalPercent: number;
  userId?: string;
}
const reduceGoalTotalDefaultValue: ReduceGoalContextProps = {
  emsn: 0, years: [], goalType: 1,
  midYear: 0, midPercent: 0,
  goalYear: 0, goalPercent: 0,
};

export const ReduceGoalContext = React.createContext({
  total: reduceGoalTotalDefaultValue,
  setTotal: (value: ReduceGoalContextProps) => { },
});

export const ReduceGoalContextProvider = (props: any) => {
  const setTotal = (value: ReduceGoalContextProps) => {
    setState({ ...state, total: value });
  }

  let initialDefaultState = { total: reduceGoalTotalDefaultValue, setTotal: setTotal };
  const [state, setState] = useState(initialDefaultState);

  return (
    <ReduceGoalContext.Provider value={state}>
      {props.children}
    </ReduceGoalContext.Provider>
  )
}
export default ReduceGoalContextProvider;
