import ArcButton, { ButtonColor } from "components/arc/ArcButton";
import ArcGrid, { ArcGridCheckResult, GridApi, GridEditor } from "components/arc/ArcGrid";
import ArcSelectbox, { SelectboxSize } from "components/arc/ArcSelectbox";
import { AuthConstant } from "constants/authConstant";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { BasicInfoService } from "services/cp/BasicInfoService";
import { useAppSelector } from "stores/hook";
import { isStringNullOrEmpty } from "utils/stringUtil";
import { UserInfoUtil } from "utils/userInfoUtil";

export default function Industry() {
  const [selectDgr, setSelectDgr] = useState("");
  const [maxDgr, setMaxDgr] = useState("");
  const [selectDgrList, setSelectDgrList] = useState<any>([])
  const gridRef: any = useRef<typeof ArcGrid>();
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const [gridKey, setGridKey] = useState(0);

  useEffect(() => {
    getMaxDgr();
  }, [])

  async function getMaxDgr() {
    const maxDgrResponse = await BasicInfoService.getInstance().getMaxDgrByBzknd();
    if (maxDgrResponse && maxDgrResponse?.resultCode == 'Y') {
      setSelectDgr(maxDgrResponse.data);
      setMaxDgr(maxDgrResponse.data);
      const selectBoxArr = [];
      for (let i = Number(maxDgrResponse.data); i > 0; i--) {
        let data = { label: `${i}`, value: `${i}` };
        selectBoxArr.push(data);
      }
      setSelectDgrList(selectBoxArr);
    } else {
      toast.error("데이터를 조회하는 중 오류가 발생하였습니다.");
    }
  }

  // handleChangeSelect 함수 수정
  const handleChangeSelectbox = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectDgr(event.target.value);
  };

  const clickCopyPrevDgr = async () => {
    // 차수 추가
    let newDgr = (Number(selectDgr) + 1).toString();
    let newSelectDgrList = selectDgrList;
    setSelectDgr(newDgr)
    let data = { label: `${newDgr}`, value: `${newDgr}` };
    newSelectDgrList.push(data);
    setSelectDgrList(newSelectDgrList);

    const response = await BasicInfoService.getInstance().duplicationData();
    if (response) {
      if (response.data == true && response.resultCode == 'Y') {
        toast.success("정상적으로 처리가 완료되었습니다.");
        setGridKey(prevKey => prevKey + 1);
      }
    } else {
      toast.error("데이터 처리 중 오류가 발생하였습니다.")
    }
  }

  const onCheckedRow = (e: ArcGridCheckResult) => {
    if (e.isChecked) {
      if (e.checkedRows.length > 3) {
        toast.error("3개 이상의 데이터를 한번에 삭제할 수 없습니다.");
        gridRef.current?.getInstance().uncheckAll();
      }
    };
  }

  const adminGridApi: GridApi = {
    readData: { url: "/co/bzknd", method: "GET", initParams: { dgr: selectDgr } },
    createData: { url: "/co/bzknd", method: "POST" },
    updateData: { url: "/co/bzknd", method: "PUT" },
    deleteData: { url: "/co/bzknd", method: "DELETE" },
  };

  const gridApi: GridApi = {
    readData: { url: "/co/bzknd", method: "GET", initParams: { dgr: selectDgr } },
  };

  // 그리드 컬럼
  const gridColumns: any[] = [
    {
      header: "bzkndId",
      name: "bzkndId",
      hidden: true,
    },
    {
      header: "dgr",
      name: "dgr",
      hidden: true,
    },
    {
      header: "구분",
      name: "bzkndNm",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value ?? "";
      },
    },
    {
      header: "코드",
      name: "emksBzkndCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value ?? "";
      },
    },
    {
      header: "배출 계수 적용 기준",
      name: "crbnEmsnCoefIndsClCds",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value) {
          return formatCoefName(e.value);
        }
        return "";
      },
    },
  ];

  function formatCoefName(value: any): string {
    let result = "";
    if (Array.isArray(value)) {
      value.forEach((item: { crbnEmsnCoefIndsClCdNm: string }) => {
        result += "▪" + item.crbnEmsnCoefIndsClCdNm;
      });
    } else if (typeof value === 'string') {
      result = value;
    }
    if (result != "" && result.slice(0, 1) == "▪") {
      result = result.slice(1,);
    }
    return result;
  }

  // 그리드 수정함수
  function handleChangeEditor(e: any) {
  }

  const gridEditor: GridEditor = {
    defaultRowDataSet: {
      dgr: selectDgr,
    },
    isDataHotSwap: false,
    editableColumns: [
      {
        columnName: "bzkndNm",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "string",
          validation: {
            required: true,
            dataType: "string"
          },
        },
      },
      {
        columnName: "emksBzkndCd",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,20}?$/,
            unique: true,
            required: true,
            regExpMessage: "전체 길이는 20자리까지 가능합니다.",
          },
        },
      },
      {
        columnName: 'crbnEmsnCoefIndsClCds',
        editorType: 'checkbox',
        options: {
          onChange: handleChangeEditor,
          validation: {
            required: true,
            validatorFn: (value: any) => {
              if (isStringNullOrEmpty(value)) {
                return false;
              } else {
                return true;
              }
            }
          },
          listItems: [
            { text: "에너지산업", value: "에너지산업" },
            { text: "제조업", value: "제조업" },
            { text: "건설업", value: "건설업" },
            { text: "상업", value: "상업" },
            { text: "공공", value: "공공" },
            { text: "가정", value: "가정" },
            { text: "기타", value: "기타" },
          ],
        }
      },
    ],
  };

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap mgb10">
            <h3 className="titT1">표준산업분류 중분류</h3>

            <ArcSelectbox
              label={"차수"}
              name={"seq"}
              options={selectDgrList}
              onChange={handleChangeSelectbox}
              selectedValue={selectDgr}
              size={SelectboxSize.px150}
              key={selectDgrList}
            />
            <div style={{ marginLeft: "10px" }}>
              {
                selectDgr == maxDgr && UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN) ?
                  <ArcButton
                    type={"save"}
                    text={"이전 차수 복사"}
                    onClick={clickCopyPrevDgr}
                    color={ButtonColor.btn1}
                  /> : <></>
              }
            </div>
          </div>

          {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
            ?
            <ArcGrid
              gridRef={gridRef}
              key={selectDgr}
              datasource={adminGridApi}
              columns={gridColumns}
              rowHeaders={["checkbox"]}
              useGridInfoHeader={true}
              usePagination={false}
              editor={gridEditor}
              onCheckRow={onCheckedRow}
              useStaticData={false}
              selectDgr={selectDgr}
            />
            :
            <ArcGrid
              gridRef={gridRef}
              key={gridKey}
              datasource={gridApi}
              columns={gridColumns}
              useGridInfoHeader={true}
              usePagination={false}
              onCheckRow={onCheckedRow}
              useStaticData={false}
            />
          }
        </div>
      </div>
    </div>
  );
}
