import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import useAppInput from "../../stores/useAppInput";
import {DefaultTFuncReturn} from "i18next";
import ObjectUtil from "utils/objectUtil";
import {Link} from "react-router-dom";

export type SelectDataModel = {
  label: string,
  value: any
}

export enum SelectboxSize {
  default = "InpSel",
  w5 = "InpSel-w5",
  w10 = "InpSel-w10",
  w15 = "InpSel-w15",
  w20 = "InpSel-w20",
  w30 = "InpSel-w30",
  w40 = "InpSel-w40",
  w50 = "InpSel-w50",
  w60 = "InpSel-w60",
  w70 = "InpSel-w70",
  w80 = "InpSel-w80",
  w90 = "InpSel-w90",
  w100 = "InpSel-w100",
  px100 = "InpSel-100",
  px150 = "InpSel-150",
  px200 = "InpSel-200",
  px250 = "InpSel-250",
  px300 = "InpSel-300",
  px350 = "InpSel-350",
  px400 = "InpSel-400",
  px450 = "InpSel-450",
  px500 = "InpSel-500"

}

type SelectboxProps = {
  id?: string,
  name: string,
  onChange: (data?: any) => void,
  options: SelectDataModel[],
  selectedValue?: any,
  size?: SelectboxSize,
  useDefaultOption?: boolean,
  useDefaultOptionName?: string,
  disabled?: boolean,
  className?: string,
  isRequired?: boolean,
  canSelectMultiple?: boolean,
  validateMessage?: string,
  label?: string | DefaultTFuncReturn;
  useLabelFor?: boolean;
  title?: string;
  style?: React.CSSProperties;
  callFromSearchPicker?: boolean;
}

export default function ArcSelectbox(props: Readonly<SelectboxProps>) {
  const {
    id = props.id ?? props.name,
    name = props.name,
    onChange,
    options = [],
    selectedValue,
    size = SelectboxSize.default,
    useDefaultOption = false,
    useDefaultOptionName = '선택하세요',
    disabled,
    className,
    isRequired = false,
    label,
    useLabelFor = label && (props.useLabelFor ?? true),
    title,
    style,
    callFromSearchPicker = props.callFromSearchPicker ?? false,
  } = props;

  const [optionList, setOptionList] = useState(options);
  const [selected, setSelected] = useState('');
  const [hasFocus, setHasFocus] = useState(false);
  const ref = useRef<any>();
  const [selectValue, handleChangeSelectValue, isValid] = useAppInput({
    type: 'select',
    initialValue: selected,
  });

  useEffect(() => {
    setOptionList(options);
  }, [options])

  useEffect(() => {
    let selectValue: string = '';
    if ('number' === typeof selectedValue) {
      selectValue = selectedValue.toString();
    } else {
      selectValue = selectedValue;
    }

    setSelected(selectValue);
    let returnValue: any = {target: {value: selectValue, name: name ?? ''}};
    if (selectValue !== null && selectValue !== '' && selectedValue !== undefined) {
      const selectOptionValue = options.filter((f) => f.value?.toString() === selectValue);
      if (selectOptionValue.length > 0) {
        returnValue = {target: {value: selectOptionValue[0].value, name: selectOptionValue[0].label}};
        handleChangeSelectValue(returnValue);
      }
    } else {
      handleChangeSelectValue(returnValue);
    }
  }, [selectedValue, selected]);


  const [isRequiredMessageShown, setIsRequiredMessageShown] = useState(false);
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.target.blur(); // prevent change event when focus out
    const selectedOptionValue = event.target.value;
    handleChangeSelectValue(event);
    onChange(event);

    setIsRequiredMessageShown(ObjectUtil.isEmpty(selectedOptionValue));
  };

  const selectBoxClassName: string = makeSelectBoxClassName();

  function makeSelectBoxClassName() {
    let selectBoxClass = 'select-group' + ' ' + size;
    selectBoxClass += hasFocus ? ' focus' : '';

    if (isRequired && !isValid) {
      selectBoxClass += ' InpSel-wrong ';
    }

    return selectBoxClass;
  }

  const handleFocus = (e: React.FocusEvent<HTMLSelectElement>) => {
    setHasFocus(e.type === 'focus');
  }

  const createSelect = () => {
    return (
        <>
          <div className={selectBoxClassName + ' ' + (className ?? '')} style={style}>
            <select
                ref={ref}
                id={id}
                name={name}
                value={selectValue}
                onChange={handleSelectChange}
                onFocus={handleFocus}
                onBlur={handleFocus}
                disabled={disabled}
                title={title}
                className={isRequired && isRequiredMessageShown ? 'InpSel-wrong' : ''}
                required>
              {useDefaultOption ? (
                  <option value={""}>{useDefaultOptionName || '선택하세요'}</option>
              ) : (
                  ''
              )}
              {optionList.map((ele: SelectDataModel) => (
                  <option key={ele.value} value={(ele.value ?? "").toString()}>
                    {ele.label}
                  </option>
              ))}
            </select>
            {isRequired && isRequiredMessageShown ? (
                <em className="select-wrong-txt">필수 선택 항목입니다.</em>
            ) : (
                ''
            )}
          </div>
        </>
    );
  }

  return (
      <>
        {label && (
            <label htmlFor={useLabelFor ? id : crypto.randomUUID()} style={{marginRight: 0}}>
              <span className="srh-tit">{label}</span>
            </label>
        )}
        {
          callFromSearchPicker
              ? <div className={'calendar-picker'}>{createSelect()}</div>
              : <>{createSelect()}</>
        }
      </>
  );
}